import { Component } from "react";
import CfgDisplay from './config/CfgDisplay.jsx'
import TableauBord from "./exec/TableauBord.jsx";

class TableauBordMain extends Component{


    constructor(props){
        super(props);
        this.state={"annee":new Date().getFullYear()}
    }


    anneeOnChange=(event)=>{
        const newAnneeNum= event.target.value
        this.setState({"annee":newAnneeNum})
    }
    render(){
        const startyear= new Date().getFullYear()+1-5
        const yearlist=Array(5).fill().map((element, index) => index+startyear)
        return(<div>
                <select  name="annee" value={this.state.annee} onChange={this.anneeOnChange}>
                    {yearlist.map((year) => {return(<option value={year} key={year}> {year}-{year+1} </option>)})}
                </select>
                <TableauBord year={this.state.annee} startmonth={7}/>
            </div>);
    }
}

export default TableauBordMain