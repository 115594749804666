import { Component } from "react";
import Day from "./Day"
import styles from "./DayModule.module.css"
import genstyles from "../components.module.css"
import GeneralContext from '../general-context'
import PersonSelector from "../PersonSelector";


function  getLundiStr(datestr){
    var date= new Date(datestr)
    var dayNum= date.getDay()-1
    var lundiDate= new Date(date.getTime())
    lundiDate.setDate(lundiDate.getDate()- dayNum)
    var lundiDatestr= lundiDate.toISOString().substring(0, 10);
    return lundiDatestr
};


class DayArray extends Component {
    ///////////DATE PAS BESOIN D ETRE DANS LE STATE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    constructor(props){
        super(props);
        //if props.date = null then date = today
        //var  startDate = '2021-07-15'
        var startDate= new Date().toISOString().substring(0, 10);
        if (this.props.onlyLundis){
            startDate= getLundiStr(startDate)
        }
        this.state = {startDateStr:startDate, personDict:{}, saveAllSignal: 0, lockAllSignal: 0, unlockAllSignal: 0, selectedID:null,can_lock:false,can_unlock:false};
        this.state.daysNotSaved=[]
    };

    setNotSavedStatus(index,isNotSavedValue){
        this.setState((state,props)=>{
            const array= state.daysNotSaved
            const newArray= [...array]
            newArray[index]=isNotSavedValue
            return({daysNotSaved:newArray})
        })
    }
        
    arrayNotSaved(){
        console.log('ARRAYNOTSAVED? TRIGGERED')
        console.log(this.state.daysNotSaved)
        for (const isNotSaved of this.state.daysNotSaved){
            if (isNotSaved){
                return true
            }
        }
        return false
    }

    handleChangeDays(afterConfirmFunc){
        console.log('HANDLECHANGEDAYS TRIGGERED')
        if (this.arrayNotSaved()){
            if(window.confirm("Des modifications n'ont pas été sauvegardées, êtes-vous sur de vouloir quitter la page?")){
                afterConfirmFunc()
            }
        }
        else{
            afterConfirmFunc()
        }
    }
    handleUnload(){
        console.log('HANDLE UNLOAD TRIGGERED....................................')
        if (this.arrayNotSaved()){
            return("Des modifications n'ont pas été sauvegardées, êtes-vous sur de vouloir quitter la page?")
        }
    }

    saveAll= () => {
        console.log('Sending saveall signal')
        this.setState((state, props) => ({
            saveAllSignal: state.saveAllSignal + 1 
          }));      
    }

    lockAll= ()=>{
        console.log('Sending lockall signal')
        this.setState((state, props) => ({
            lockAllSignal: state.lockAllSignal + 1 
          })); 
    }

    unlockAll= ()=>{
        console.log('Sending unlockall signal')
        this.setState((state, props) => ({
            unlockAllSignal: state.unlockAllSignal + 1 
          })); 
    }
    /*setSelectedIdOnChange= (event)=>{
        var id=event.target.value
        this.setState({selectedID: id})
    }*/

    setSelectedIDAndLock= (id)=>{
        this.handleChangeDays(()=>{this.setState({'selectedID': id,'can_lock':this.state.personDict[id].can_lock,'can_unlock':this.state.personDict[id].can_unlock})})
    }



    setPersonDict= (dict)=> {
        this.setState({personDict:dict})
    }


    offsetDatesTEST= (offset)=>{
        var date= new Date(this.state.startDateStr)
        var newDate= newDate(date.getTime()+(offset*24*60*60*1000))
        var datestr= newDate.toISOString().substring(0, 10);
        this.setState({startDateStr:datestr})
    }
    offsetDates= (offset)=>{
        var date= new Date(this.state.startDateStr)
        date.setDate(date.getDate()+offset)
        date.setTime(date.getTime()+1000*3600*2)
        var datestr= date.toISOString().substring(0, 10);
        this.setState({startDateStr:datestr})
    }

    onDateChange=(event)=>{
        this.handleChangeDays(()=>{var newDateStr=event.target.value
            if (this.props.onlyLundis){
                newDateStr=getLundiStr(newDateStr)
            }
            this.setState({startDateStr:newDateStr})})
        
    }

    

    goLeft= ()=>{
        this.handleChangeDays(()=>{var arraySize= this.props.arraySize
            this.offsetDates(-arraySize)})
        
    }

    goRight= ()=>{
        this.handleChangeDays(()=>{var arraySize= this.props.arraySize
            this.offsetDates(arraySize)})
    }
    
    getWeekNumber=()=> {
        // Copy date so don't modify original
        const d = new Date(this.state.startDateStr);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
        // Return array of year and week number
        return weekNo
    }
        
    componentDidMount() {
        window.addEventListener("beforeunload", this.handleUnload);
    }

    componentWillUnmount(){
        window.addEventListener("beforeunload", this.handleUnload);
    }

    render(){

        var selectedPerson= this.state.personDict[this.state.selectedID]
        var  lockbutton =null
        var  unlockbutton= null
        if (selectedPerson!= null){
            if (selectedPerson.can_lock==true){
                lockbutton= <button className={styles.button} onClick={this.lockAll}> Bloquer heures </button>
            }
            if (selectedPerson.can_unlock==true){
                unlockbutton= <button className={styles.button} onClick={this.unlockAll}> Déloquer heures </button>
            }
        }
        


        const daysList=[]
        {for (var index = 0; index < this.props.arraySize; index++){
            var date=new Date(this.state.startDateStr)
            date.setDate(date.getDate() + index)
            const setNotSavedForIndex= this.setNotSavedStatus.bind(this,index)
            daysList.push(<Day date={date} personID={this.state.selectedID} setNotSaved={setNotSavedForIndex} saveSignal={this.state.saveAllSignal} lockSignal={this.state.lockAllSignal} unlockSignal={this.state.unlockAllSignal}/>)
        }}
        //this.state.dateArray.map(date => <li key={date}><Day date={date} personID={this.state.selectedID} saveSignal={this.state.saveAllSignal}/> </li>)

        return(
            <div className={styles.dayArrayOuterStyle}>
                <PersonSelector personDict={this.state.personDict}
                        selectedID={this.state.selectedID}
                        setSelectedID={this.setSelectedIDAndLock}
                        setPersonDict={this.setPersonDict}
                        manager_id={this.context.id_employe}
                        source="managed"/>
                <div className={styles.dayArrayRibbon}>
                    <p>Semaine {this.getWeekNumber()}</p>
                    <button className={styles.button} onClick={this.goLeft}> &lt; </button>
                    <input className={genstyles.blueInput} type='date' id='start' value={this.state.startDateStr} onChange={this.onDateChange}/>
                    <button className={styles.button} onClick={this.goRight}> &gt; </button>
                </div>
                {lockbutton}
                {unlockbutton}
                
                <div className={styles.dayArrayInnerStyle}>
                    {daysList}              
                </div>
                <button className={styles.button+' '+ styles.wide+ ' '+styles.saveAllButton} onClick={this.saveAll}>SAUVEGARDER</button>
            </div>);
    };
};

DayArray.contextType = GeneralContext
export default DayArray;