import { Component } from "react";
import FileUpload from "./FileUpload.jsx"
import { getTxCours, getCheck } from "./TravauxCoursComms.js";
import GeneralContext from "../general-context.js";
import styles from "../components.module.css"
import {LoadButton} from "../utils.js"

class TxCours extends Component{

    constructor(props){
        super(props);
        this.state={'useEndDate':false,'endDate':null, 'debutCheckHeuresDevis':null}
    }

    onUseEndDateChange= (event)=>{
        this.setState({'useEndDate':event.target.checked})
    }

    onEndDateChange= (event)=>{
        this.setState({'endDate':event.target.value})
    }

    onDebutCheckHeuresDevisChange= (event)=>{
        this.setState({'debutCheckHeuresDevis':event.target.value})
    }

    changeTxCours= async ()=>{
        if (this.state.useEndDate){
            const response= await getTxCours(this.context,this.state.endDate)
            return response
        }
        else{
            const response= await getTxCours(this.context)
            return response
        }
    };

    checkCodesHeures= async()=>{
        
        const response= await getCheck(this.context,this.state.debutCheckHeuresDevis)
        return response

    };

    render(){
        var endDateInput= null
        if (this.state.useEndDate){
            endDateInput=<input type='date' id='endDate' value={this.state.endDate} onChange={this.onEndDateChange}/>
        };

        console.log(this.monthslist)
        console.log(this.props.year)
        return(<div >
            <p>Utilise les memes fichiers achats que gestion, attention</p>
            <LoadButton onClick={this.changeTxCours} text={"modifier et renvoyer fichier tx_cours"}/>
            <FileUpload filestr="tx_cours"/>

            <p>Utiliser une date butoir pour les heures employés et achats (incluse)?</p>
            <input type="checkbox" id='useEndDate' checked={this.state.useEndDate} onChange={this.onUseEndDateChange}/>
            {endDateInput}

        </div>
        )
    }
}
TxCours.contextType = GeneralContext
export default TxCours