import { Component } from "react";
import styles from "./DayModule.module.css"
class DayTable extends Component{

    constructor(props){
        super(props);
    }

    makeSessionsOfMomentTable(slots, label){
        const slotslen= slots.length
        //console.log('SLOTS IN SESSIONSPRINT')
        //console.log(slots)
        const rows=[]
        for (let i=0;i<slotslen;i++){
            const slot=slots[i]
            //console.log('slot om SessionsPrint')
            //console.log(slot)
            var labelcell=null
            if (i==0){
                labelcell=<td rowspan={slotslen}>{label}</td>
            }
            const rowElement=[labelcell,<td>{slot.heuresminutes}</td>,<td>{slot.code_chantier}</td>]

            rows.push(rowElement)
        }
        return rows
    }

    makeSessionsTable(sessionsPerMomentDict){
        const momentlabels={'matin':'Matin','aprem':'Après-midi', 'nuit':'Nuit'}
        const labelrow= [<th className={styles.recapColMoment}></th>,
            <th className={styles.recapColHeures}>Heures</th>, 
            <th className={styles.recapColChantier}>Chantier</th>]
        const stylesarray= [styles.recapColMoment,styles.recapColHeures,styles.recapColChantier]
        var valuerows=[]
        for (const [key, value] of Object.entries(sessionsPerMomentDict)){
            valuerows=valuerows.concat(this.makeSessionsOfMomentTable(value,momentlabels[key]))
        }
        var everythingempty= true
        for (const row of valuerows){
            if (row.length>0){
                everythingempty=false
            }
        }
        if (everythingempty){
            valuerows=[[<td></td>,<td></td>,<td></td>]]
        }
        return {'labelrow':labelrow,'valuerows':valuerows, 'styles':stylesarray}
    }
    makeInfosTable(dayInfo){
        const colnames= ['lieu','trajet','vehicule_pers','panier_repas','absence','absence_journee','remarques']
        const tablelabels= ['Lieu','Trajet','Vehic_Pers','Panier','Motif_absen','AbsJourEntier','Remarques']
        const stylesdict= {'remarques':styles.recapColRemarques,
            'lieu':styles.recapColLieu,
            'trajet': styles.recapColTrajet,
            'vehicule_pers':styles.recapColVehicule,
            'panier_repas':styles.recapColPanier,
            'absence':styles.recapColAbsence,
            'absence_journee': styles.recapColJourEntAbsence}
        const values=[]
        const labels=[]
        const stylesarray=[]
        console.log('DAYINFO : ')
        console.log(dayInfo)
        var index=0
        for (const colname of colnames){
            labels.push(<th className={stylesdict[colname]}>{tablelabels[index]}</th>)
            var value= null
            if (dayInfo[colname] === true){
                value= '1'
            }
            else if (dayInfo[colname] === false){
                value='0'
            }
            else{
                console.log('COLNAME: ' + colname.toString() + 'VALUE: '+ dayInfo[colname])
                value= dayInfo[colname]
            }
            console.log('STYLES of '+ colname)
            console.log(stylesdict[colname])
            values.push(<td className={stylesdict[colname]}>{value}</td>)
            index=index+1
        }
        //console.log('valuerow')
        //console.log( values)
        return {'labelrow':labels,'valuerow':values}
    }

    sessionSort(dayInfo){
        const matinSessions=[]
        const apremSessions=[]
        const nuitSessions=[]
        const sessMomentDict= {'matin':matinSessions,'aprem':apremSessions,'nuit':nuitSessions}
        for (const sess of dayInfo.sessions){
            if (sess.moment=='matin'){
                matinSessions.push(sess)
            }
            else if (sess.moment=='aprem'){
                apremSessions.push(sess)
            }
            else if(sess.moment=='nuit'){
                nuitSessions.push(sess)
            }
            else{
                throw new Error('moment non reconnu' + sess)
            }
        }
        const compareSess= (sa,sb)=>{return (sa.ordre>=sb.ordre)}
        matinSessions.sort(compareSess)
        apremSessions.sort(compareSess)
        nuitSessions.sort(compareSess)
        return sessMomentDict
    }

    makeFullTable(dayInfo){
        //console.log('dayInfo in makefulltable:///////////////////////////')
        //console.log(dayInfo)
        const sessDict=this.sessionSort(dayInfo)
        const sessTableDict= this.makeSessionsTable(sessDict)
        const infosTableDict= this.makeInfosTable(dayInfo)


        for (const labelCell of infosTableDict.labelrow){
            sessTableDict.labelrow.push(labelCell)
        }
        for (const valuecell of infosTableDict.valuerow){
            sessTableDict.valuerows[0].push(valuecell)
        }

        const width= sessTableDict.labelrow.length

        
        const dayHeader= <thead className={styles.dayTableTitleTH}><th className={styles.dateth} colspan={width}>{this.props.dayStr}</th></thead>
        const finaltable=[dayHeader]
        finaltable.push(<thead>{sessTableDict.labelrow}</thead>)

        const finalvaluerows=[]
        for (const row of sessTableDict.valuerows){
            finalvaluerows.push(<tr>{row}</tr>)
        }
        finaltable.push(<tbody>{finalvaluerows}</tbody>)
        return finaltable
    }

    render(){
        if (this.props.dayInfo!==null){
            return(<table className={styles.recaptable}>
                {this.makeFullTable(this.props.dayInfo)}
            </table>)
        }
        else{
            return(<table className={styles.recaptable}><thead><th className={styles.dateth}>{this.props.dayStr+ ' (NON REMPLI)'}</th></thead></table>)
        }
    }
}

export default DayTable;