import { Component } from "react";
import GeneralContext from "./general-context";
import styles from "./components.module.css"
import {PersonInfo} from "./utils"

class PersonSelector extends Component{

    constructor(props){
        super(props);
    }

    async getManagedPersonList(){
        const selfID= this.props.manager_id
        console.log(this.context.baseURL)
        var targetURL = new URL(this.context.baseURL + "/employes_manages")
        var paramsString = new URLSearchParams({id_manager: selfID}).toString()
        targetURL.search = paramsString
        var response = await this.context.jsonFetcher(targetURL, null, 'GET')
        return response
    }


    managedPersonDictSetter(managedList){
        var personDict= {}
        for (let p of managedList){
            var pInfo= new PersonInfo(p.id, p.prenom, p.nom,p.can_lock,p.can_unlock)
            personDict[pInfo.id]= pInfo
        }
        this.props.setPersonDict(personDict)
    }
    /*managedPersonDictSetter(manager,setPersonDict,setSelectedID){
        var managerInfo= new PersonInfo(manager.id, manager.prenom, manager.nom)
        var personDict= {}
        personDict[managerInfo.id]= managerInfo
        for (let p of manager.manages){
            var pInfo= new PersonInfo(p.id, p.prenom, p.nom)
            personDict[pInfo.id]= pInfo
        }
        setPersonDict(personDict)
        //setSelectedID(this.context.id_employe)
    }*/

    setAllPersons(dict){
        this.props.setPersonDict(dict)
    }

    personDictSetter(persons){
        var personDict= {}
        for (let p of persons){
            var pInfo= new PersonInfo(p.id, p.prenom, p.nom)
            personDict[pInfo.id]= pInfo
        }
        this.setAllPersons(personDict)
    }

    async getAllPersonList(){
        var targetURL = new URL(this.context.baseURL + "/employe")
        var paramsString = new URLSearchParams({get_all: true}).toString()
        targetURL.search = paramsString
        var response = await this.context.jsonFetcher(targetURL, null, 'GET')
        return response
    }


    loadPersonList= async ()=>{
        if (this.props.source=="managed"){
            var response = await this.getManagedPersonList()
            if (response.status== 200){
                if (response.json!==null){
                    this.managedPersonDictSetter(response.json)
                }
                else{
                    throw new Error('Exception message');
                }
            }
            else{
                throw new Error('Exception message');
            }
        }
        else if(this.props.source=="all"){
            var response = await this.getAllPersonList()
            if (response.status== 200){
                if (response.json!==null){
                    this.personDictSetter(response.json)
                }
                else{
                    throw new Error('Exception message');
                }
            }
            else{
                throw new Error('Exception message');
            }
        }
        this.props.setSelectedID(this.context.id_employe)
    }
    
    componentDidMount(){
        this.loadPersonList()
    }

    setSelectedIdOnChange= (event)=>{
        this.props.setSelectedID(event.target.value)
    }

    render(){       
        console.log(this.props.selectedID) 
        return(
        <div className={styles.personSelectorDiv}>
            <select  className={styles.personSelector+ ' '+styles.blueInput} name="person" value={this.props.selectedID} onChange={this.setSelectedIdOnChange}>
                {Object.entries(this.props.personDict).map(([id,pers]) => {return(<option value={id} key={id}> {pers.nom + ' ' + pers.prenom} </option>)})}
            </select>
        </div>
        )
    }

}

PersonSelector.contextType = GeneralContext
export default PersonSelector;