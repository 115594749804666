import { Component } from "react";
import FileUpload from "./FileUpload.jsx"
import {getTableauBord} from "../tableaubordComms.js"
import GeneralContext from "../../general-context";
import styles from "../../components.module.css"
import {LoadButton} from "../../utils.js"

class TableauBord extends Component{

    constructor(props){
        super(props);
        
    }

    makeTableau=async()=>{
        const response= await getTableauBord(this.props.startmonth,this.props.year,this.context)
        return response
    }
    render(){
        console.log(this.props.year)
        var monthslist=[]
        var month=this.props.startmonth
        var year=parseInt(this.props.year)
        for (let i=0;i<12;i++){
            monthslist.push({month:month,year:year})
            if (month>=12){
                month=1
                year=year+1
            }
            else{
                month=month+1
            }
            
        }
        return(<div >
            <LoadButton onClick={this.makeTableau} text={"Creer tableau de bord"}/>
            <div className={styles.CRlist}>
                {monthslist.map(dict => {return( <FileUpload month={dict.month} year={dict.year}/>)})}
            </div>
        </div>
        )
    }
}
TableauBord.contextType = GeneralContext
export default TableauBord