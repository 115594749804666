import styles from './DayModule.module.css'
import { Component } from "react";
import React, { useState } from 'react';
import {GeneralContext} from '../general-context' 

class Absence extends Component{

    static contextType = GeneralContext

    constructor(props){
        super(props);
    };

    render(){

        if (this.props.displayValues){

            /*
                    <label htmlFor="all_day" className={styles.dayLabelStyle}> Absence journée entière </label>
                    <input disabled={this.props.disabled} type="checkbox" name="all_day" value={this.props.allDayValue} checked={this.props.allDayValue} onChange={this.props.allDayOnChange}/>
            */
            var values=<div>
                    <label htmlFor="heures_absence" className={styles.dayLabelStyle}> Heures absence </label>
                    <input name="heures_absence" disabled={this.props.disabled} className={styles.dayInputStyle} type="number" maxLength="1" required
                        onChange={this.props.heuresAbsenceOnChange}
                        value={this.props.heuresAbsenceValue}/>
                    <label htmlFor="raisonAbsence" className={styles.dayLabelStyle}> Raison absence </label>
                    <select disabled={this.props.disabled} className={styles.dayInputStyle} name="raisonAbsence" 
                        onChange={this.props.reasonOnChange}
                        value={this.props.reasonValue}>
                        {this.context.params["raisons_absence"].map((reason, index) =>
                        <option key={reason} value={reason}>{reason}</option>
                        )}
                    </select>
                </div>
        }
        else{
            var values=null
        }

        return(
            <div className={styles.halfDayStyle}>
                <div className={styles.horizontalFlex}>
                    <label htmlFor="absence" className={styles.halfDayTitle}> Absence </label>
                    <input type="checkbox" disabled={this.props.disabled} name="absence" value={this.props.displayValues} checked={this.props.displayValues} onChange={this.props.displayOnChange}/>
                </div>

            {values}
                
            </div>
        
        )};

}
Absence.contextType = GeneralContext
export default Absence