import { Component } from "react";
import styles from "./DayModule.module.css"
import genstyles from "../components.module.css"
import GeneralContext from '../general-context'
import PersonSelector from "../PersonSelector";
import DayTable from "./DayTable";
import RecapTotals from "./recapTotals";
import logo from './e6logoflat.jpg';

function  getLundiStr(datestr){
    console.log('getlundistr triggered')
    var date= new Date(datestr)
    var dayNum= date.getDay()-1
    var lundiDate= new Date(date.getTime())
    lundiDate.setDate(lundiDate.getDate()- dayNum)
    var lundiDatestr= lundiDate.toISOString().substring(0, 10);
    return lundiDatestr
};


class RecapArray extends Component {
    ///////////DATE PAS BESOIN D ETRE DANS LE STATE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    static defaultStateNoDate= {personDict:{}, selectedID:null,daysInfo:[],daysRecap:[],daysTotal:null}
    constructor(props){
        
        super(props);
        //if props.date = null then date = today
        var startDate= new Date().toISOString().substring(0, 10);
        if (this.props.onlyLundis){
            startDate= getLundiStr(startDate)
        }
        this.state = {...RecapArray.defaultStateNoDate}
        this.state.startDateStr=startDate
    };

    resetState(){
        this.setState({daysInfo:[]})
    }

    componentDidMount(){
        if (this.state.selectedID !== null){
            this.refreshDaysInfo()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (prevState.selectedID!=this.state.selectedID || prevState.startDateStr!=this.state.startDateStr){
            if (this.state.selectedID!== null){
                console.log('day state changed and personID not null')
                this.resetState()
                this.refreshDaysInfo()
            }
        }
    }

    async refreshDaysInfo(){
        const daysInfo=await this.retrieveDaysInfo()
        const daysRecap=[]
        const daysTotal= <RecapTotals daysInfo={daysInfo}/>
        for (const dayInfo of daysInfo){
            
            daysRecap.push(<DayTable dayInfo={dayInfo.info} dayStr={dayInfo.dayStr}/>)
        }
        this.setState({'daysRecap':daysRecap, 'daysTotal':daysTotal})
    }

    async retrieveDaysInfo(){
        const daysInfo=[]
        for (var index = 0; index < this.props.arraySize; index++){
            var date=new Date(this.state.startDateStr)
            date.setDate(date.getDate() + index)
            var dateStr= date.toISOString().substring(0, 10)
            var idEmp= this.state.selectedID
            
            const response= await this.retrieveDay(date.getFullYear(),date.getMonth()+1,date.getDate(),idEmp)
            if (response.status!=200){
                console.log(response)
                throw new Error("Erreur de récuperation du jour "+ dateStr)
            }
            const dayInfo=response.body
            console.log('DAY INFO AFTER FETCH')
            console.log(dayInfo)
            const weekday = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"];
            const dayStr= weekday[date.getDay()]+' '+date.toLocaleDateString()
            daysInfo.push({'info':response.body,'dayStr':dayStr})
        }
        return daysInfo 
    }


    async retrieveDay(year,month,day, idEmp){
        var dayInfo={"year":year,"month":month, "day":day, "id_employe":idEmp}
        var targetURL = new URL(this.context.baseURL + "/jour")
        var paramsString = new URLSearchParams(dayInfo).toString()
        targetURL.search = paramsString
        var response = await this.context.jsonFetcher(targetURL, null, 'GET')
        
        return {body:response.json, status: response.status}
    }
        
    setPersonDict= (dict)=> {
        this.setState({personDict:dict})
    }

    offsetDates= (offset)=>{
        var date= new Date(this.state.startDateStr)
        date.setDate(date.getDate()+offset)
        date.setTime(date.getTime()+1000*3600*2)
        var datestr= date.toISOString().substring(0, 10);
        this.setState({startDateStr:datestr})
    }

    onDateChange=(event)=>{
        var newDateStr=event.target.value
        if (this.props.onlyLundis){
            newDateStr=getLundiStr(newDateStr)
        }
        this.setState({startDateStr:newDateStr})
    }

    

    goLeft= ()=>{
        var arraySize= this.props.arraySize
        this.offsetDates(-arraySize)
    }

    goRight= ()=>{
        var arraySize= this.props.arraySize
        this.offsetDates(arraySize)
    }
    
    getWeekNumber=()=> {
        // Copy date so don't modify original
        const d = new Date(this.state.startDateStr);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
        // Return array of year and week number
        return weekNo
    }
    
    setSelectedID= (id)=>{
        this.setState({'selectedID': id})
    }


    openPrintWindow= ()=>{
        window.open(this.state.daysRecap)
    }
    render(){      
        //this.state.dateArray.map(date => <li key={date}><Day date={date} personID={this.state.selectedID} saveSignal={this.state.saveAllSignal}/> </li>)
        const selectedPerson=this.state.personDict[this.state.selectedID]
        var denomperson
        if (selectedPerson!==undefined){
            denomperson= selectedPerson.prenom + ' '+ selectedPerson.nom
        }
        else{
            denomperson=''
        }
        return(

            
            <div className={styles.dayArrayOuterStyle}>
                <div className={styles.dayArrayRibbon}>
                    <img className={styles.onlyDisplayOnPrint} src={logo} alt="logo" />
                    <h2>{denomperson}    Semaine {this.getWeekNumber()}</h2>
                    <div className={styles.dayInputs}> <button className={styles.button} onClick={this.goLeft}> &lt; </button>
                        <input className={genstyles.blueInput} type='date' id='start' value={this.state.startDateStr} onChange={this.onDateChange}/>
                        <button className={styles.button} onClick={this.goRight}> &gt; </button> </div>
                    <div className={styles.dayInputs}><PersonSelector personDict={this.state.personDict}
                            selectedID={this.state.selectedID}
                            setSelectedID={this.setSelectedID}
                            setPersonDict={this.setPersonDict}
                            manager_id={this.context.id_employe}
                            source="managed"/> </div>
                        
                        
                    
                </div>
                
                <div className={styles.recaparray}>
                    {this.state.daysTotal}
                    {this.state.daysRecap}              
                </div>
            </div>);
    };
};

RecapArray.contextType = GeneralContext
export default RecapArray;