import { Component } from "react";
import {patchCompte, getCompte } from "./adminComms";

export default class CompteDetails extends Component{
    constructor(props){
        super(props);
        this.state={}
        this.state.username=''
        this.state.type_compte=''
        this.state.pwd=''
    }

    async refreshCompte(){
        var response = await getCompte(this.props.username,this.props.context)
        if (response.status== 200){
            if (response.json!==null){
                var compteJson= response.json
                //setting it
                this.setState((state, props) => {
                    var newstate= {}
                    newstate.username=compteJson.username
                    newstate.type_compte= compteJson.type_compte
                    newstate.manage_interims= compteJson.manage_interims
                    return(newstate)});
            }
            else{
                throw new Error('Exception message');
            }
        }
        else{
            console.log("not good2")
            throw new Error('Exception message');
        }
    }

     usernameOnChange= (event) =>{
        this.setState({username:event.target.value})
    }

    typeCompteOnChange= (event) =>{
        this.setState({type_compte:event.target.value})
    }

    pwdOnChange= (event) =>{
        this.setState({pwd:event.target.value})
    }

    mngInterimsOnChange= (event) =>{
        this.setState({manage_interims:event.target.checked})
        console.log('comptedetails state')
        console.log(this.state)
    }

    saveUsername= ()=>{
        patchCompte(this.props.username,'username', this.state.username, this.props.context)
        this.close()
    }

    saveTypeCompte= ()=>{
        patchCompte(this.props.username,'type_compte', this.state.type_compte, this.props.context)
        this.refresh()
    }

    savePwd= ()=>{
        patchCompte(this.props.username,'password', this.state.pwd, this.props.context)
        this.refresh()
    }

    saveMngInterims= ()=>{
        patchCompte(this.props.username,'manage_interims', this.state.manage_interims, this.props.context)
        this.refresh()
    }

    refresh= () =>{
        this.refreshCompte()
    }

    componentDidMount(){
        this.refresh()
    }

    close=()=>{
        this.props.close()
    }
    render(){

        /*<label htmlFor="username">username</label>
            <input type="text" name="username" value={this.state.username} onChange={this.usernameOnChange}/>
            <button onClick={this.saveUsername}>Sauvegarder username</button>*/
        
        return(
        <div>
            <button onClick={this.close}>X</button>
            <button onClick={this.refresh}>RAFRAICHIR</button>
            <label htmlFor="type_compte">Type de compte</label>
            <select name="comptes" value={this.state.type_compte} onChange={this.typeCompteOnChange}>
                {this.props.typeslist.map(([desc,type]) => {return(<option value={type} key={type}> {desc} </option>)})}
            </select>
                      <button onClick={this.saveTypeCompte}>Sauvegarder Type Compte</button>
            <label htmlFor="pwd">Mot de passe</label>
            <input type="password" name="pwd" value={this.state.pwd} onChange={this.pwdOnChange}/>
            <button onClick={this.savePwd}>Sauvegarder mot de passe</button>
        </div>
        )
    }
}