import {makeFetchFunction,makeJsonFetchFunction,makeFileFetchFunction} from './comms'
import React from 'react'


const tokenSetter= (token) => {
  this.token = token;
  this.fetcher = makeFetchFunction(token);
  this.jsonFetcher=  makeJsonFetchFunction(this.fetcher)
}

export function initGeneralContext(token, prenom, nom, id_personne, type_compte, params, apiURL, savedDayState, setSavedDayState){
  var fetcherFunction= makeFetchFunction(token)
  var jsonFetcherFunction= makeJsonFetchFunction(fetcherFunction)
  var fileFetcherFunction= makeFileFetchFunction(token)
  return {
    token: token,
    fetcher: fetcherFunction,
    jsonFetcher: jsonFetcherFunction,
    fileFetcher: fileFetcherFunction,
    tokenSetter : tokenSetter,
    prenom: prenom,
    nom: nom,
    id_employe: id_personne,
    type_compte:type_compte,
    params: params,
    baseURL:apiURL+'/api',
    savedDayState:savedDayState,
    setSavedDay:setSavedDayState
    //baseURL:"http://192.168.1.175:5000"
  }
}

export const GeneralContext =  React.createContext({
    token: null, // valeur par défaut
    fetcher: ()=>{},
    tokenSetter: tokenSetter,
    jsonFetcher: ()=>{},
    prenom: null,
    nom: null,
    id_employe: null,
    params: null,
    baseURL: null,
    savedDayState:null,
    setSavedDay:()=>{}
  }
);

export default GeneralContext