import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../components.module.css'


 async function loginUser(credentials, apiURL) {
  console.log('loginuser reached')
  const targetURL= apiURL+"/auth/login"
  return fetch(targetURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(function(response) {
      if(response.ok) {
        var json=response.json()
        console.log('Bonne réponse: '+ json);
        return(json)
      } else {
        window.alert('Réponse négative serveur, mauvais mot de passe/nom utilisateur: '+ response.json());
        return(null)
      }
    })
 }
 
export default function Login(props) {

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {

    e.preventDefault();
    try{
      const loginJson = await loginUser({username,password},props.apiURL);
      console.log('API URL: ' + props.apiURL)
      console.log('just before setLoginJson')
      console.log(loginJson)
      props.setLoginJson(loginJson);
    }
    catch(error){
      window.alert('Erreur de communication serveur, veuillez réessayer: '+ String(error))
    }
    
  }

  return(
    <div className="login-wrapper">
      <h1>Connexion</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="usernameInput"> Nom d'utilisateur </label>
          <input className={styles.whiteBackground} name="usernameInput" type="text" onChange={e => setUserName(e.target.value)}/>
        </div>
        <div>
          <label htmlFor="passwordInput"> Mot de passe </label>
          <input className={styles.whiteBackground}  name="passwordInput" type="password" onChange={e => setPassword(e.target.value)}/>
        </div>
        <div>
          <button className={styles.whiteBackground}  type="submit">Se connecter</button>
        </div>
      </form>
    </div>
  )
}

  Login.propTypes = {
    setLoginJson: PropTypes.func.isRequired
  }