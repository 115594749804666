import styles from './DayModule.module.css'
import genstyles from "../components.module.css"

function DayInput(props) {
    return (
    <div className={styles.dayDivElementStyle}>
        <label htmlFor={props.name} className={styles.dayLabelStyle}> {props.label} </label>
        <input disabled={props.disabled} className={styles.dayInputStyle+ ' '+ genstyles.blueInput} width="30%"  type="text" name={props.name} maxLength="40" required
            onChange={props.onChange}
            value={props.value}/>
    </div>
        )
}
export default DayInput;