import styles from './DayModule.module.css'

function AddButton (props){

    return(
        <button disabled={props.disabled} className= {styles.wide +' '+ styles.button+' '+styles.addButton} onClick={props.onclickfunc}>
            {"+"}
        </button>);
}

export default AddButton;