import { Component } from "react";

import Compte from './Compte.jsx'

class ListeComptes extends Component{

    render(){
        console.log('PROPS')
        console.log(this.props)
        return(
        <ol>
            {this.props.listeComptes.map((compte,index)=>{return(<li key={index}><Compte compte={compte} 
            setNumeroCompte={(event)=>{this.props.setNumeroCompte(index,event)}} setDebutPlage={(event)=>{this.props.setDebutPlage(index,event)}} 
            setFinPlage={(event)=>{this.props.setFinPlage(index,event)}}/></li>)})}
        </ol>)
    }
}

export default ListeComptes