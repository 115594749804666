import { Component } from "react";
import {PersonInfo} from "../utils";
import styles from "./viz.module.css"
import genStyles from "../components.module.css"

export default class CheckboxSelector extends Component {

    onCheckBoxChange=(event)=>{
        const elementId=event.target.value
        const  checked= event.target.checked
        if (checked){
            this.props.addElement(elementId)
        }
        else{
            this.props.removeElement(elementId)
        }
    }


    render(){
        return(
            <div>
                <div className={styles.centeredHorizontalDiv}>
                    {Object.values(this.props.listedElements).map(element => 
                    {return(<div className={styles.horizontalDivWithBorder}><span>{element.toString()}</span><input type="checkbox" id={element.id} value={element.id}
                    checked={(element.id in this.props.pickedElements)} onChange={this.onCheckBoxChange}/></div>)})}
                </div>
                <button className={genStyles.whiteButton} onClick={this.props.checkAll}>COCHER TOUT</button>
                <button className={genStyles.whiteButton} onClick={this.props.uncheckAll}>DECOCHER TOUT</button>
            </div>)
    }
}