import { findByLabelText } from '@testing-library/react'
import DashButton from './DashButton'
import styles from './Dashboard.module.css'
import GeneralContext from '../general-context'
import { Component } from "react";

const saisieJour = ['Saisie jour', '/saisiejour']
const saisieSemaine = ['Saisie semaine', '/saisiesemaine']
const settings = ['Paramètres', '/parametres']
const viz = ['Recherche', '/recherche']
const admin = ['Admin', '/admin']
const recap = ['Recap/impression', '/recap']
const tableauBord= ['Tableau de Bord', '/tableaubord']
const paies=['Export excel BDD', '/bdd']
const managerheures= ['Heures par manager', '/managerheures']
const gestion= ['Gestion', '/gestion']
const travauxcours= ['Travaux en cours', '/travauxcours']

//const items = [saisieJour, saisieSemaine, settings, viz, admin]

class Dashboard extends Component{

    constructor(props){
        super(props);
    }

    render(){
        //<h2>dashboard page</h2>
        var items = [saisieJour,recap]
        if (!(/Android|iPhone/i.test(navigator.userAgent))) {
            items.push(saisieSemaine)
        }
        const typeCompte= this.context.type_compte
        if (['SuperAdmin','GeneralManagerUser'].includes(typeCompte)){
            items.push(viz)
            
        }
        if(['SuperAdmin','ComptaUser'].includes(typeCompte)){
            items.push(tableauBord)
            items.push(paies)
            items.push(managerheures)
            items.push(gestion)
        }
        if (typeCompte=='SuperAdmin'){
            items.push(admin)
            items.push(travauxcours)
        }
        console.log(navigator.userAgent)
        console.log(typeCompte)
        return (
        <div>
            <h2>MENU PRINCIPAL</h2> 
            <div className ={styles.dashlist}>
                {items.map(item => {return(<DashButton text={item[0]} key={item[0]} target={item[1]} />)})}
            </div>        
        </div>
        )
    }

}

Dashboard.contextType = GeneralContext
export default Dashboard;