export async function getAccounts(context){
    var targetURL = new URL(context.baseURL + "/compte")
    var paramsString = new URLSearchParams({get_all: true}).toString()
    targetURL.search = paramsString
    var response = await context.jsonFetcher(targetURL, null, 'GET')
    return response
}


export async function getCompte(username, context){
    var targetURL = new URL(context.baseURL + "/compte")
    var paramsString = new URLSearchParams({username: username}).toString()
    targetURL.search = paramsString
    var response = await context.jsonFetcher(targetURL, null, 'GET')
    return response
}
export async function patchCompte(username, field, value, context){
    var targetURL = new URL(context.baseURL + "/compte")
    var paramsString = new URLSearchParams({username: username}).toString()
    targetURL.search = paramsString
    var compte= {}
    compte[field]=value
    var payload=JSON.stringify(compte)
    var response = await context.jsonFetcher(targetURL, payload, 'PATCH')
    return response
}

export async function postEmploye(prenom, nom, type, context, telephone=null){
    var targetURL = new URL(context.baseURL + "/employe")
    var persemploye= {prenom:prenom, nom:nom, telephone:telephone, type:type}
    var payload=JSON.stringify(persemploye)
    var response = await context.jsonFetcher(targetURL, payload, 'POST')
    return response
}

export async function deleteEmploye(idEmploye, context){
    var targetURL = new URL(context.baseURL + "/employe")
    var paramsString = new URLSearchParams({id: idEmploye}).toString()
    targetURL.search = paramsString
    var response = await context.jsonFetcher(targetURL, null, 'DELETE')
    return response
}

export async function postCompte(username, password, id_personne, type_compte, context){
    var targetURL = new URL(context.baseURL + "/compte")
    var compte= {username:username, password:password, id_personne:id_personne, type_compte:type_compte}
    var payload=JSON.stringify(compte)
    var response = await context.jsonFetcher(targetURL, payload, 'POST')
    return response
}

export async function deleteCompte(username,context){
    var targetURL = new URL(context.baseURL + "/compte")
    var paramsString = new URLSearchParams({username: username}).toString()
    targetURL.search = paramsString
    var response = await context.jsonFetcher(targetURL, null, 'DELETE')
    return response
}
export async function getEmployes(context){
    var targetURL = new URL(context.baseURL + "/employe")
    var paramsString = new URLSearchParams({get_all: true}).toString()
    targetURL.search = paramsString
    var response = await context.jsonFetcher(targetURL, null, 'GET')
    return response
}

export async function getEmploye(id, context){
    var targetURL = new URL(context.baseURL + "/employe")
    var paramsString = new URLSearchParams({id: id}).toString()
    targetURL.search = paramsString
    var response = await context.jsonFetcher(targetURL, null, 'GET')
    return response
}
export async function patchEmploye(id, field, value, context){
    var targetURL = new URL(context.baseURL + "/employe")
    var paramsString = new URLSearchParams({id: id}).toString()
    targetURL.search = paramsString
    var employe= {}
    employe[field]=value
    var payload=JSON.stringify(employe)
    var response = await context.jsonFetcher(targetURL, payload, 'PATCH')
    return response
}

export async function postManage(manage, manager, context){
    var targetURL = new URL(context.baseURL + "/manage")
    var compte= {"manage":manage, "manager":manager}
    var payload=JSON.stringify(compte)
    var response = await context.jsonFetcher(targetURL, payload, 'POST')
    return response
}

export async function deleteManage(manage, manager, context){
    var targetURL = new URL(context.baseURL + "/manage")
    var compte= {"manage":manage, "manager":manager}
    var payload=JSON.stringify(compte)
    var response = await context.jsonFetcher(targetURL, payload, 'DELETE')
    return response
}

function editPersonne(){

}

function addAccount(){

}
function editACcount(){

}
function deactivatePersonne(){

}

function deactivateAccount(){

}

