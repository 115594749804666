import { Component } from "react";
import GeneralContext from "../general-context";
import {getAccounts, getEmployes, deleteCompte, postCompte, postEmploye, deleteEmploye} from "./adminComms"
import {nullToEmptyStr} from "../utils"
import PasswordChanger from "./PasswordChanger";
import EmployeDetails from "./EmployeDetails";
import CompteDetails from "./CompteDetails";
import Adder from "./Adder";
import VerticalGroup from "./VerticalGroup";
 
class CompteInfo{
    constructor(id, prenom, nom, username){
        this.personne={}
        this.personne.id=id
        this.personne.nom=nom
        this.personne.prenom= prenom
        this.username=username
    }

    getStringRep(){
        return(this.username+ ' (' + this.personne.prenom + ' '+ this.personne.nom + ')')
    }
}

class PersonneInfo{
    constructor(id, prenom, nom){
        this.id=id
        this.nom=nom
        this.prenom= prenom
    }

    getStringRep(){
        return(this.prenom + ' '+ this.nom + ' (' + String(this.id)+  ')')
    }
}

export default class adminComponent extends Component{

    typescompteslist= [['compte qui ne peut que gérer ses propres heures', 'LowUser'],
                    ['compte qui peut gérer ses heures et celles des interim', 'MediumUser'],
                    ['compte manager qui peut gérer heures de tout le monde et bloquer heures', 'GeneralManagerUser']]
    
    typesemployeslist= [['interim','interim'],['employe','employe'], ['manager','manager'],['superadmin (a eviter)','super']]
    constructor(props){
        super(props)
        this.state={}
        this.state.employes={}
        this.state.selectedEmployeId=0
        this.state.comptes={}
        this.state.selectedCompteUsername=''
        this.state.pendingEmployes= false
        this.state.pendingComptes= false
        this.state.showComponent= null
    }

    makeCompteDict(accountsJson){
        var compteDict={}
        for (let a of accountsJson){
            var compte= new CompteInfo(a.personne.id, a.personne.prenom, a.personne.nom,a.username)
            compteDict[compte.username]=compte
        }
        return compteDict
    }

    async refreshComptes(){
        var response = await getAccounts(this.context)
        if (response.status== 200){
            if (response.json!==null){
                //making the dict from the response
                var compteDict= this.makeCompteDict(response.json)
                //setting it
                this.setState((state, props) => {
                    var newstate= {}
                    newstate.comptes = compteDict
                    if (!(state.selectedCompteUsername in compteDict)){
                        console.log('setting new selected username because not present')
                        newstate.selectedCompteUsername=Object.keys(compteDict)[0]
                    }
                    return(newstate)});
            }
            else{
                throw new Error('Exception message');
            }
        }
        else{
            console.log("not good2")
            throw new Error('Exception message');
        }
    }

    makeEmployeDict(employesJson){
        var employeDict={}
        for (let e of employesJson){
            var employe= new PersonneInfo(e.id, e.prenom, e.nom)
            employeDict[employe.id]=employe
        }
        return employeDict

    }

    async refreshEmployes(){
        var response = await getEmployes(this.context)
        if (response.status== 200){
            if (response.json!==null){
                var employeDict=this.makeEmployeDict(response.json)
                //setting it
                this.setState((state, props) => {
                    var newstate= {}
                    newstate.employes = employeDict
                    if (!(state.selectedEmployeId in employeDict)){
                        console.log('setting new selected ID because not present')
                        newstate.selectedEmployeId=Object.keys(employeDict)[0]
                    }
                    return(newstate)});
            }
            else{
                throw new Error('Exception message');
            }
        }
        else{
            console.log("not good2")
            throw new Error('Exception message');
        }
    }


    refresh=()=>{
        this.refreshComptes()
        this.refreshEmployes()
        console.log('refresh end')
    }

    onEmployeChange=(event)=>{
        this.setState({selectedEmployeId:event.target.value})
    }

    onCompteChange=(event)=>{
        this.setState({selectedCompteUsername:event.target.value})
    }

    componentDidMount(){
        this.refresh()
    }

    openEmployeDetails=()=>{
        var empDetails= <EmployeDetails typeslist={this.typesemployeslist} idEmploye={this.state.selectedEmployeId} context={this.context} close={this.closeShowComponent}/>
        this.setState({showComponent:empDetails}) 
    }

    openCompteDetails=()=>{
        var compteDetails= <CompteDetails typeslist={this.typescompteslist} username={this.state.selectedCompteUsername} context={this.context} close={this.closeShowComponent}/>
        this.setState({showComponent:compteDetails}) 
    }

    closeShowComponent=()=>{
        this.setState({showComponent:null})
        this.refresh()
    }

    addEmploye= async (employeDict)=>{
        var result= await postEmploye(employeDict.prenom,employeDict.nom, employeDict.type, this.context)
        if (result.status==201){
            return true
        }
        else{
            return false
        }
    }

    addCompte= async (compteDict)=>{
        var result=await postCompte(compteDict.username,compteDict.password, compteDict.id_personne, compteDict.type_compte,this.context)
        if (result.status==201){
            return true
        }
        else{
            return false
        }
    }

    openAddEmploye= ()=>{
        var fields=[['nom',{'type':"text"}] , ['prenom',{'type':"text"}], ['type',{'type':"select",'selectlist':this.typesemployeslist}]]
        var component= <Adder fields={fields} onConfirm={this.addEmploye} preloaded={{}} onClose={this.closeShowComponent}/>
        this.setState({showComponent:component})
    }

    openAddCompte= ()=>{
        var fields=[['username',{'type':"text"}], ['password',{'type':"password"}],['type_compte',{'type':'select','selectlist':this.typescompteslist}]]
        var preloaded={id_personne:this.state.selectedEmployeId}
        var currEmploye=this.state.employes[this.state.selectedEmployeId]
        var title="Ajout de compte pour "+ currEmploye.nom + " " + currEmploye.prenom
        var component= <Adder fields={fields} onConfirm={this.addCompte} title={title} preloaded={preloaded} onClose={this.closeShowComponent}/>
        this.setState({showComponent:component})
    }

    removeCompte= ()=>{
        var confirmed = window.confirm("Voulez vous supprimer le compte " + this.state.selectedCompteUsername+ "?")
        if (confirmed){
            deleteCompte(this.state.selectedCompteUsername,this.context)
            this.refresh()
        }
        
    }

    render(){

        if (this.state.showComponent!==null){
            return this.state.showComponent
        }
            
        else{
            return(
                <div>



                    <button onClick={this.refresh}>RAFRAICHIR</button>
                    <VerticalGroup title='Employés'>
                        <select name="employes" value={this.state.selectedEmployeId} onChange={this.onEmployeChange}>
                            {Object.entries(this.state.employes).map(([id,emp]) => {return(<option value={id} key={id}> {emp.getStringRep()} </option>)})}
                        </select>
                        <button onClick={this.openEmployeDetails}>VOIR DETAILS EMPLOYE</button>
                        <button onClick={this.openAddEmploye}>CREER NOUVEL EMPLOYE</button>
                    </VerticalGroup>
                    
                    
                    <VerticalGroup title='Comptes'>
                        <select name="comptes" value={this.state.selectedCompteUsername} onChange={this.onCompteChange}>
                            {Object.entries(this.state.comptes).map(([id,com]) => {return(<option value={id} key={id}> {com.getStringRep()} </option>)})}
                        </select>
                        <button onClick={this.openCompteDetails}>DETAILS COMPTE</button>
                        <button onClick={this.removeCompte}>SUPPRIMER COMPTE</button>
                        <select name="employescompte" value={this.state.selectedEmployeId} onChange={this.onEmployeChange}>
                            {Object.entries(this.state.employes).map(([id,emp]) => {return(<option value={id} key={id}> {emp.getStringRep()} </option>)})}
                        </select>
                        <button onClick={this.openAddCompte}>AJOUTER COMPTE A EMPLOYE CI-DESSUS</button>
                    </VerticalGroup>
                      
                    
                    
                </div>
            )
        }

    }
        
}

adminComponent.contextType = GeneralContext