import { Component } from "react";

import HalfDay from "./HalfDay"
import MidDay from "./MidDay"
import styles from './DayModule.module.css'
import genstyles from "../components.module.css"
import GeneralContext from '../general-context'
import DayInput from "./DayInput"
import DayToggle from "./DayToggle"
import StatusDisplay from "./StatusDisplay";
import {nullTo0, nullToEmptyStr} from "../utils"
import Absence from "./Absence"
import {fetchHandling} from '../comms'

function makeReadyJsonFetcher(jsonFetcher,targetURL, body, method){
    const readyFetcher= async ()=>{
        var response = await jsonFetcher(targetURL, body, method)
        return {body:response.json, status: response.status}
    }
    return readyFetcher
}


class Day extends Component{

    static defaultState = {annonces:[],bloque:false,remarques: "", lieu: "", trajet: "", vehicule_pers: false, panier_repas: false, nextSlotID:1, matinSlots: [], apremSlots: [], nuitSlots: [], displayNuit: false,absenceReason:null,absenceAllDay:false,absence:false, status:"default"}

    constructor(props){
        super(props);
        this.state = {...Day.defaultState}
    }
    

    
    resetState(){
        var newState= {...Day.defaultState}
        this.setState(newState)
    }

    sendStateToContext= ()=>{
        this.context.setSavedDay(this.state)
        console.log('passé par copier')
    }


    makeTimeslotCopy(timeslotInfo){
        var newTimeSlot={}
        for (const [key,value] of Object.entries(timeslotInfo)){
            newTimeSlot[key]=value
        }
        return newTimeSlot
    }

    makeTimeslotListCopy(timeslots){
        var newlist=[]
        for (const timeslot of timeslots){
            newlist.push(this.makeTimeslotCopy(timeslot))
        }
        return newlist
    }

    copySavedStateToDay= ()=>{
        if (this.state.bloque==false){
            const savedDayState= this.context.savedDayState
            var newState={remarques: savedDayState.remarques,
            lieu: savedDayState.lieu,
            trajet: savedDayState.trajet,
            vehicule_pers: savedDayState.vehicule_pers,
            panier_repas: savedDayState.panier_repas,
            nextSlotID: savedDayState.nextSlotID,
            matinSlots: this.makeTimeslotListCopy(savedDayState.matinSlots),
            apremSlots: this.makeTimeslotListCopy(savedDayState.apremSlots),
            nuitSlots: this.makeTimeslotListCopy(savedDayState.nuitSlots),
            displayNuit: savedDayState.displayNuit,
            absenceReason: savedDayState.absenceReason,
            absenceAllDay:savedDayState.absenceAllDay,
            absence:savedDayState.absence,
            status:"modified_day"}
            
            this.setState(newState)
            console.log('passé par coller')
            //console.log(this.context)
            console.log(newState)
        }
        
    }

    componentDidMount(){
        if (this.props.personID!== null){
            this.retrieveDay()
            this.retrieveAnnonces()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps.saveSignal != this.props.saveSignal) {
            this.saveDay()
        }
        if (prevProps.lockSignal != this.props.lockSignal) {
            this.bloqueJour()
        }
        if (prevProps.unlockSignal != this.props.unlockSignal) {
            this.debloqueJour()
        }
        if(prevState.trajet!='GD' && this.state.trajet=='GD'){
            console.log('panier repas mis a false car GD')
            this.setState({'panier_repas':false})
        }
        if (prevProps.personID!==this.props.personID || prevProps.date.toISOString().substring(0, 10)!==this.props.date.toISOString().substring(0, 10)){
            if (this.props.personID!== null){
                console.log('day props changed and personID not null')
                this.resetState()
                this.retrieveDay()
                this.retrieveAnnonces()
            }
        }
        if (!(prevState.status==this.state.status)){
            console.log('SETNOTSAVED TRIGERRED........................................')
            this.props.setNotSaved(this.isNotSaved())
        }
    }

    generalHandleChange(stateLabel, event){
        var newstate = {}
        var targetType= event.target.type
        console.log(targetType)
        if (targetType!= "checkbox"){
            newstate[stateLabel] = event.target.value
        }
        else{
            newstate[stateLabel] = event.target.checked
        }
        newstate.status='modified_day'
        this.setState(newstate)
    }


    addSlot(list) {
        this.setState((state, props) => {
            var newstate= {}
            newstate[list] = state[list].concat({id: state.nextSlotID, heures: 0, quart:0, chantier: '', code_chantier: ''})
            newstate['nextSlotID']= state.nextSlotID + 1
            return(newstate)});
    };

    slotStateChangeFactory(list, idToChange,fieldname){
        const slotStateChanger= (event) => {
            this.setState((state, props) => {
                var newArr= [...state[list]]
                for (let i=0;i<newArr.length;i++){
                    console.log('i value')
                    console.log(i)
                    if (newArr[i].id==idToChange){
                        console.log('idisequal')
                        newArr[i][fieldname]=event.target.value
                        var newstate={}
                        newstate[list]=newArr
                        newstate.status='modified_day'
                        return(newstate)
                    }
                }
            })
        }   
        return(slotStateChanger)    
    };

    slotRemoverFactory(list, idToRemove){
        const slotRemover= () => {
            this.setState((state, props) => {
                var newstate= {}
                newstate[list]= state[list].filter(slot => slot.id != idToRemove)
                newstate.status='modified_day'
                return(newstate)});
        }
        return slotRemover
    }

    sessionListToValues(dictOfSessionArr){
        var resList=[]
        for (const moment in dictOfSessionArr){
            const arr = dictOfSessionArr[moment]
            var index=0
            for (const session of arr){
                var sessionInfo={}
                sessionInfo.chantier=''
                sessionInfo.code_chantier=session.code_chantier
                sessionInfo.heures=session.heures
                sessionInfo.minutes=session.quart
                sessionInfo.moment=moment
                sessionInfo.ordre=index
                index=index+1
                resList.push(sessionInfo)
            }
        }
        return(resList)
    }
    dayToJson(){
        var dayValues= {}
        dayValues.date= this.props.date.toISOString().substring(0, 10)
        dayValues.year=this.props.date.getFullYear()
        dayValues.month=this.props.date.getMonth()+1
        dayValues.day=this.props.date.getDate()
        dayValues.remarques= this.state.remarques
        dayValues.trajet= this.state.trajet
        dayValues.vehicule_pers= this.state.vehicule_pers
        dayValues.panier_repas= this.state.panier_repas
        dayValues.lieu= this.state.lieu

        var dictOfSessionsArr= {'matin': this.state.matinSlots, 'aprem': this.state.apremSlots, 'nuit':this.state.nuitSlots}
        dayValues.sessions= this.sessionListToValues(dictOfSessionsArr)
        dayValues.id_employe= this.props.personID

        if (this.state.absence){
            dayValues.absence=this.state.absenceReason
            dayValues.absence_journee=this.state.absenceAllDay
            dayValues.heures_absence= this.state.heures_absence
        }
        else{
            dayValues.absence=null
            dayValues.absence_journee=false
            dayValues.heures_absence= 0
        }

        var jsonToSend = JSON.stringify(dayValues)
        return jsonToSend
    }

    jsonToDay(dayJson){
        var newPartialState = {}
        newPartialState.remarques=nullToEmptyStr(dayJson.remarques) 
        newPartialState.trajet= nullToEmptyStr(dayJson.trajet)
        newPartialState.vehicule_pers= nullToEmptyStr(dayJson.vehicule_pers)
        newPartialState.lieu=nullToEmptyStr(dayJson.lieu)
        newPartialState.panier_repas=nullToEmptyStr(dayJson.panier_repas)
        newPartialState.nextSlotID=0
        newPartialState.bloque=dayJson.bloque
        newPartialState.matinSlots=[]
        newPartialState.apremSlots=[]
        newPartialState.nuitSlots=[]
        if (dayJson.absence==null){
            newPartialState.absence=false
            newPartialState.absenceAllDay=false
            newPartialState.absenceReason=""
            newPartialState.heures_absence=0
        }
        else {
            newPartialState.absence=true
            newPartialState.absenceAllDay=dayJson.absence_journee
            newPartialState.absenceReason=dayJson.absence
            newPartialState.heures_absence=dayJson.heures_absence
        }
        

        for (const session of dayJson.sessions){
            if (session.moment == "matin"){
                var currentSlots= newPartialState.matinSlots
            }
            else if(session.moment == "aprem"){
                var currentSlots= newPartialState.apremSlots
            }
            else if(session.moment == "nuit"){
                var currentSlots= newPartialState.nuitSlots
                newPartialState.displayNuit=true
            }
            else{
                console.log("Une session a un moment non reconnu: " + session.moment)
                return
            }
            var slotInfo= {}
            slotInfo.id= newPartialState.nextSlotID
            newPartialState.nextSlotID= newPartialState.nextSlotID+1
            slotInfo.heures= nullTo0(session.heures)
            slotInfo.chantier= nullToEmptyStr(session.chantier)
            slotInfo.code_chantier= nullToEmptyStr(session.code_chantier)
            slotInfo.quart= nullTo0(session.minutes)
            currentSlots[session.ordre]=slotInfo
        }
        newPartialState.status='unmodified_day'
        this.setState(newPartialState)
    }

    async retrieveAnnonces(){
        const jour=this.props.date.getDate()
        const mois=this.props.date.getMonth()+1
        const annee=this.props.date.getFullYear()
        const idpers=this.props.personID
        var targetURL = new URL(this.context.baseURL + "/annonces")
        var paramsString = new URLSearchParams({"jour":jour,"mois":mois,"annee":annee,"idpers":idpers}).toString()
        targetURL.search= paramsString
        var response = await this.context.jsonFetcher(targetURL, null, 'GET')
        console.log('ANNONCES DANS RESPONSE')
        console.log(response)
        this.setState({"annonces":response.json.annonces})
    }

    async retrieveDay(day){
        //var dayInfo={"date":this.props.date.toISOString().substring(0, 10), "id_employe":this.props.personID}
        var dayInfo={"year":this.props.date.getFullYear(), "month": this.props.date.getMonth()+1, "day": this.props.date.getDate(),"id_employe":this.props.personID}
        /*dayValues.year=this.props.date.getFullYear()
        dayValues.month=this.props.date.getMonth()+1
        dayValues.day=this.props.date.getDate()*/
        var targetURL = new URL(this.context.baseURL + "/jour")
        var paramsString = new URLSearchParams(dayInfo).toString()
        targetURL.search = paramsString

        /*const readyFetcher= async ()=>{
            var response = await this.context.jsonFetcher(targetURL, null, 'GET')
            var status= response.status
            var json= response.json
            console.log('JSOOOOOOOOOOOOOOOOOOOOOOON remarques' + String(json.remarques))
            return {body:json,status:status}
        }*/

        const readyFetcher=makeReadyJsonFetcher(this.context.jsonFetcher, targetURL, null, 'GET')
        
        const bodyHandler= (json, status)=>{
            if (status== 200){
                if (json!==null){
                    this.jsonToDay(json)
                }
                else{
                    console.log("no day found")
                    this.setState({status:'no_day_found'})
                }
            }
            else{
                console.log("not good")
                throw new Error('Exception message');
            }
        }
        
        fetchHandling(readyFetcher,bodyHandler)
    }

    async sendDayToBase(){
        for (let slotsList of [this.state.matinSlots,this.state.apremSlots,this.state.nuitSlots]){
            for (let slot of slotsList){
                if (!this.codeChantierIsValid(slot.code_chantier)){
                    window.alert('Le code chantier doit être composé de 7 chiffres')
                    return
                }
            }
        }
        var jsonToSend= this.dayToJson()
        console.log(jsonToSend)
        var targetURL = new URL(this.context.baseURL + '/jour')
        const readyFetcher=makeReadyJsonFetcher(this.context.jsonFetcher,targetURL, jsonToSend, 'POST')
        
        const responseHandler= (json, status)=>{
            if (status==200){
                this.jsonToDay(json)
                this.setState({status:'saved_day'})
            }
            else{
                this.setState({status:'error_save_day'})
            }
        }
        

        fetchHandling(readyFetcher,responseHandler)
    }

    displayNuitOnChange= (event) =>{
        var display= event.target.checked
        this.setState({displayNuit:display,nuitSlots:[]})
    }
    displayAbsenceOnChange= (event) =>{
        var display= event.target.checked
        console.log(display)
        var reason=null
        var heures=0
        if (display){
            reason="Autre"
            heures=Math.ceil(8-this.getTotHeures())
        }
        this.setState({absence:display,absenceReason:reason,absenceAllDay:false,heures_absence:heures})
    }


    async setJourBloque(jour, employeID, valeur, context){
        var targetURL = new URL(context.baseURL + "/set_jour_bloque")
        console.log('vlaue bloque:')
        console.log(valeur)
        var paramsString = new URLSearchParams({id_employe: employeID,date:jour,bloque_value:valeur}).toString()
        targetURL.search = paramsString
        var response = await context.jsonFetcher(targetURL, null, 'PATCH')
        this.resetState()
        this.retrieveDay()
        return response
    }

    getTotHeures(){
        var totHeures=0
        for (const slots of [this.state.matinSlots,this.state.apremSlots,this.state.nuitSlots]){
            for (const slot of slots){
                totHeures=totHeures+parseInt(slot.heures)+(parseInt(slot.quart)/60)
            }
        }
        return totHeures
    }
    
    bloqueJour= ()=>{
        this.setJourBloque(this.props.date.toISOString().substring(0, 10),this.props.personID,true,this.context)
    }

    debloqueJour= ()=>{
        this.setJourBloque(this.props.date.toISOString().substring(0, 10),this.props.personID,false,this.context)
    }
    codeChantierIsValid= (cc)=>{
        const reg=/^\d{7}$/
        if (reg.test(cc)){
            return true
        }
        else{
            return false
        }
    }
    saveDay(){
        if (this.state.status!='unmodified_day'){
            this.sendDayToBase()
        }
    }

    isNotSaved(){
        if ('modified_day'===this.state.status || 'error_save_day'===this.state.status ){
            return true
        }
        else{
            return false
        }
    }

    /*handleChangeDay(afterConfirmFunc){
        if (this.isNotSaved()){
            if(confirm("Des modifications n'ont pas été sauvegardées, êtes-vous sur de vouloir quitter la page?")){
                afterConfirmFunc()
            }
        }
        else{
            afterConfirmFunc()
        }
    }
    handleUnload(){
        if (this.isNotSaved()){
            return("Des modifications n'ont pas été sauvegardées, êtes-vous sur de vouloir quitter la page?")
        }
    }*/
    render(){
        //replace with switch
        var dayColor=styles.dayDefault
        if (this.state.status=='default'){
            dayColor=styles.dayDefault
        }
        else if (this.state.status=='no_day_found'){
            dayColor=styles.dayDefault
        }
        else if(this.state.status=='unmodified_day'){
            dayColor=styles.dayDefault
        }
        else if(this.state.status=='modified_day'){
            dayColor=styles.dayDefault
        }
        else if(this.state.status=='saved_day'){
            dayColor=styles.dayDefault
        }
        else if(this.state.status=='error_save_day'){
            dayColor=styles.daySaveError
        }
        else{
            console.log('Unrecognized day')
        }
        var panierBloque= this.state.bloque||(this.state.trajet=='GD')
        
        const weekday = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"];
        const daystr= weekday[this.props.date.getDay()]+' '+this.props.date.toLocaleDateString()
        var titleStyle=null
        if (weekday[this.props.date.getDay()]=="Samedi" || weekday[this.props.date.getDay()]=="Dimanche"){
            titleStyle=styles.dayTitle+ ' '+ styles.weekendHighlight
        }
        else{
            titleStyle=styles.dayTitle
        }


        var totHeures=this.getTotHeures()

        console.log('state')
        console.log(this.state)

        const trajetslist= [{'text': 'Aucun', 'value': null, 'id': 'Aucun'}]
        for (const trajet of this.context.params["trajets"]){
            trajetslist.push({'text': trajet, 'value': trajet, 'id': trajet})
        }

        const nuitTooltip='De 22h à 6h'
        return(
        <div className={styles.dayStyle}>
            {this.state.annonces.map(annstr=><div>{annstr}</div>)}
            <StatusDisplay status={this.state.status} bloque={this.state.bloque}/>
            <div className={styles.horizontalFlex}>
                <button className={styles.button}onClick={this.sendStateToContext}>Copier jour</button>
                <div className={titleStyle}>{daystr}</div>
                <button className={styles.button} onClick={this.copySavedStateToDay} disabled={this.state.bloque}>Coller jour</button>
            </div>
            <div>
                Heures jour: {totHeures}
            </div>
            <div className={styles.dayDivElementStyle}>
                <label htmlFor="trajet" className={styles.dayLabelStyle}> Trajet </label>
                <select disabled={this.state.bloque} className={styles.dayInputStyle+ ' '+ genstyles.blueInput} name="Trajet" id='trajet'
                            onChange={this.generalHandleChange.bind(this,'trajet')}
                            value={this.state.trajet}>
                            {trajetslist.map((trajet, index) =>
                            <option key={trajet.id} value={trajet.value}>{trajet.text}</option>
                            )}
                        </select>
            </div>
            <DayToggle disabled={this.state.bloque} label="Véhicule personnel" name="vehicule pers" onChange={this.generalHandleChange.bind(this,'vehicule_pers')} value={this.state.vehicule_pers}/>
            <DayInput disabled={this.state.bloque} label="Lieu" name="lieu" onChange={this.generalHandleChange.bind(this,'lieu')} value={this.state.lieu}/>
            <HalfDay disabled={this.state.bloque} label='Matin' slotsProps= {this.state.matinSlots} 
                addSlot={this.addSlot.bind(this,'matinSlots')} 
                slotStateChangerFactory={this.slotStateChangeFactory.bind(this,'matinSlots')} 
                slotRemoverFactory={this.slotRemoverFactory.bind(this,'matinSlots')}/>
            <MidDay disabled={panierBloque} panier_repas={{value:this.state.panier_repas, onChange:this.generalHandleChange.bind(this,'panier_repas')}}/>
            <HalfDay disabled={this.state.bloque} label='Après-midi' slotsProps= {this.state.apremSlots} 
                addSlot={this.addSlot.bind(this,'apremSlots')}
                slotStateChangerFactory={this.slotStateChangeFactory.bind(this,'apremSlots')}
                slotRemoverFactory={this.slotRemoverFactory.bind(this,'apremSlots')}/>

            <HalfDay disabled={this.state.bloque} label='Nuit' slotsProps= {this.state.nuitSlots} 
                addSlot={this.addSlot.bind(this,'nuitSlots')}
                slotStateChangerFactory={this.slotStateChangeFactory.bind(this,'nuitSlots')}
                slotRemoverFactory={this.slotRemoverFactory.bind(this,'nuitSlots')} 
                hasDisplayCheckbox={true}
                displayValues={this.state.displayNuit}
                displayOnChange={this.displayNuitOnChange}
                tooltipText= {nuitTooltip}/>

            <Absence disabled={this.state.bloque} reasonValue={this.state.absenceReason}
                displayValues={this.state.absence}
                displayOnChange={this.displayAbsenceOnChange}
                reasonOnChange={this.generalHandleChange.bind(this,'absenceReason')}
                heuresAbsenceValue={this.state.heures_absence}
                heuresAbsenceOnChange={this.generalHandleChange.bind(this,'heures_absence')}
                allDayValue={this.state.absenceAllDay}
                allDayOnChange={this.generalHandleChange.bind(this,'absenceAllDay')}/>
            <DayInput label="Remarques" name="remarques" onChange={this.generalHandleChange.bind(this,'remarques')} value={this.state.remarques} disabled={this.state.bloque}/>

        </div>
        )
    }
}


Day.contextType = GeneralContext
export default Day;