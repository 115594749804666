import { Component } from "react";
import {postTxCours,getFileStatus} from "./TravauxCoursComms"
import GeneralContext from "../general-context";
import styles from "../components.module.css"

class FileUpload extends Component{

    constructor(props){
        super(props);
        this.state={selectedFile:null,selectedFileName:null, mtime:null}
    }

    getCurrentStatus=async ()=>{
        const response= await getFileStatus(this.props.filestr,this.context)
        if (response.status==200){
            this.setState({mtime:response.json.mtime})
        }
        else{
            this.setState({mtime:0})
        }
    }

    uploadFile=async ()=>{
        if (this.state.selectedFile!==null){
            const response= await postTxCours(this.state.selectedFile,this.context)
            this.getCurrentStatus()
            return response.status
        }
    }

    selectedFileOnChange=(event)=>{
        const filename= event.target.value
        const file= event.target.files[0]
        this.setState({selectedFileName:filename,selectedFile:file})
    }

    componentDidMount=()=>{
        this.getCurrentStatus()
    }

    render(){
        var timestamp= ""
        if (this.state.mtime==0){
            timestamp= "Fichier non présent"
        }
        else{
            const uploaddate= new Date(this.state.mtime*1000)
            timestamp=uploaddate.toLocaleDateString()+ ' ' + uploaddate.toLocaleTimeString()
        }
        return(<div className={styles.CRdiv}>
            <p>Envoi fichier {this.props.filestr}</p>
            <p>Date upload: {timestamp}</p>
            
            <input type = "file" name = "file" value={this.state.selectedFileName} onChange={this.selectedFileOnChange}/>
        <button onClick= {this.uploadFile}>Upload</button></div>)
    }
}
FileUpload.contextType = GeneralContext
export default FileUpload