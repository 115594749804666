import { Component } from "react";

export class PersonInfo{
    constructor(id, prenom, nom, can_lock=null,can_unlock=null){
        this.id=id
        this.nom=nom
        this.prenom= prenom
        this.can_lock=can_lock
        this.can_unlock=can_unlock
    }

    toString(){
        return(this.prenom+' '+this.nom)
    }
}

export function nullTo0(value){
    if (value==null){
        return 0
    }
    else{
        return value
    }
};

export function nullToEmptyStr(value){
    if (value==null){
        return ""
    }       
    else{
        return value
    }  
};

export class LoadButton extends Component{

    constructor(props){
        super(props);
        this.state={'status':'idle'}
    }  
    onClick= async ()=>{
        this.setState({'status':'pending'})
        const status= await this.props.onClick()
        if (status==200){
            this.setState({'status':'success'})
        }
        else{
            this.setState({'status':'failure'})
        }
    }
    render(){
        var para
        if (this.state.status=='success'){
            para="succes"
        }
        else if (this.state.status=='idle'){
            para=""
        }
        else if (this.state.status=="failure"){
            para="echec"
        }
        else if (this.state.status=="pending"){
            para="en attente réponse serveur"
        }

        return(
            <div>
            <button onClick={this.onClick}> {this.props.text}</button>
                <p>{para}</p>
            </div>
        )
    }
}