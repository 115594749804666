import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

/*function CoolComponent() {
  return React.createElement('p', {}, 'Youpi So Cool !')
}

ReactDOM.render(
  React.createElement(CoolComponent),
  document.getElementById('root')
)

function CoolComponent2({ adjective = 'Cool' }) {
  return <p>Youpi So {adjective} !</p>
}

ReactDOM.render(
  <div>
    <CoolComponent2 adjective="awesome" />
    <CoolComponent2 />
  </div>,
  document.getElementById('root')
)
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
