import { Component } from "react";
import styles from "./DayModule.module.css"


class RecapTotals extends Component{

    constructor(props){
        super(props);
        
    }

    getTotalHeures(moment){
        var total=0
        for (const dayInfo of this.props.daysInfo){
            const info=dayInfo.info
            if (info !== null){
                for (const sess of info.sessions){
                    if (sess.moment==moment){
                        console.log('ajoute : ' + sess.heuresminutes.toString())
                        total=total+sess.heuresminutes
                    }
                }
            }
        }
        console.log('total : '+ total.toString())
        return total        
    }

    getTotalTrajet(trajet){
        var total=0
        for (const dayInfo of this.props.daysInfo){
            const info=dayInfo.info
            if (info!==null){
                console.log('DAYIUNFPO TRAJET ' + info.trajet.toString())
                console.log(' TRAJET: ' + trajet.toString())
                if (info.trajet==trajet){
                    console.log('added 1')
                    total=total+1
                }
            }
        }
        return total
    }
    getTotalOfInfo(fieldname){
        var total=0
        for (const dayInfo of this.props.daysInfo){
            const info=dayInfo.info
            if (info !== null){
                var value=0
                if (info[fieldname]===true){
                    value=1
                }
                else if (info[fieldname]===false){
                    value=0
                }
                else if (Number.isInteger(info[fieldname])){
                    value=info[fieldname]
                }
                else{
                    throw new Error('Asked for total or non-integer or boolean value')
                }
                total=total+value
            }
        }
        return total
    }


    render(){
        //<div className={stylesdict['trajet']}>
        const stylesdict= {'moment':styles.recapColMoment,
            'heures':styles.recapColHeures,
            'chantier':styles.recapColChantier,
            'remarques':styles.recapColRemarques,
            'lieu':styles.recapColLieu,
            'trajet': styles.recapColTrajet,
            'trajet_element':styles.recapColTrajetElement,
            'vehicule_pers':styles.recapColVehicule,
            'panier_repas':styles.recapColPanier,
            'absence':styles.recapColAbsence,
            'absence_journee': styles.recapColJourEntAbsence}
        return(<table className={styles.recaptable}>
            <thead>
                <tr>
                    <th></th>
                    <th>Heures jour</th>
                    <th>Heures nuit</th>
                    <th></th>
                    
                    <th className={stylesdict['trajet_element']}>T1A</th>
                    <th className={stylesdict['trajet_element']}>T1B</th>
                    <th className={stylesdict['trajet_element']}>T2</th>
                    <th className={stylesdict['trajet_element']}>T3</th>
                    <th className={stylesdict['trajet_element']}>T4</th>
                    <th className={stylesdict['trajet_element']}>T5</th>
                    <th className={stylesdict['trajet_element']}>GD</th>
                    <th>Vehic Pers</th>
                    <th>Paniers</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={stylesdict['moment']}> TOTAUX SEMAINE</td>
                    <td className={stylesdict['heures']}>{this.getTotalHeures('matin')+this.getTotalHeures('aprem')}</td>
                    <td className={stylesdict['chantier']}>{this.getTotalHeures('nuit')}</td>
                    
                    <td className={stylesdict['lieu']}></td>
                    
                    <td className={stylesdict['trajet_element']}>{this.getTotalTrajet('T1A')}</td>
                    <td className={stylesdict['trajet_element']}>{this.getTotalTrajet('T1B')}</td>
                    <td className={stylesdict['trajet_element']}>{this.getTotalTrajet('T2')}</td>
                    <td className={stylesdict['trajet_element']}>{this.getTotalTrajet('T3')}</td>
                    <td className={stylesdict['trajet_element']}>{this.getTotalTrajet('T4')}</td>
                    <td className={stylesdict['trajet_element']}>{this.getTotalTrajet('T5')}</td>
                    <td className={stylesdict['trajet_element']}>{this.getTotalTrajet('GD')}</td>
                    
                    <td className={stylesdict['vehicule_pers']}>{this.getTotalOfInfo('vehicule_pers')}</td>
                    <td className={stylesdict['panier_repas']}>{this.getTotalOfInfo('panier_repas')}</td>
                    <td className={stylesdict['absence']}></td>
                    <td className={stylesdict['absence_journee']}></td>

                    <td className={stylesdict['remarques']}></td>
                </tr>
            </tbody>
        </table>)
    }
}

export default RecapTotals;