import { Component } from "react";
import {postCR,getCRStatus,deleteCR} from "../tableaubordComms"
import GeneralContext from "../../general-context";
import styles from "../../components.module.css"

class FileUpload extends Component{

    constructor(props){
        super(props);
        this.state={selectedFile:null,selectedFileName:null, mtime:null}
    }

    getCurrentStatus=async ()=>{
        const response= await getCRStatus(this.props.month,this.props.year,this.context)
        console.log("RESPONSE")
        console.log(response)
        if (response.status==200){
            this.setState({mtime:response.json.mtime})     
        }
        else{
            this.setState({mtime:0})
        }
    }
    uploadFile=async ()=>{
        if (this.state.selectedFile!==null){
            const response= await postCR(this.props.month,this.props.year,this.state.selectedFile,this.context)
            this.getCurrentStatus()
        }
    }

    deleteFile=async ()=>{
        
        const response= await deleteCR(this.props.month,this.props.year, this.context)
        this.getCurrentStatus()
    }

    selectedFileOnChange=(event)=>{
        const filename= event.target.value
        const file= event.target.files[0]
        console.log("FILENAME IN FILEONCHANGE")
        console.log(filename)
        console.log("FILE IN FILEONCHANGE")
        console.log(file)
        this.setState({selectedFileName:filename,selectedFile:file})

    }

    componentDidMount=()=>{
        this.getCurrentStatus()
    }


    componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps.year!=this.props.year){
            this.getCurrentStatus()
        }
    }
    render(){
        var timestamp= ""
        if (this.state.mtime==0){
            timestamp= "Fichier non présent"
        }
        else{
            const uploaddate= new Date(this.state.mtime*1000)
            timestamp=uploaddate.toLocaleDateString()+ ' ' + uploaddate.toLocaleTimeString()
        }
        return(<div className={styles.CRdiv}>
            <p>CR du {this.props.month}/{this.props.year}</p>
            <p>Date upload: {timestamp}</p>
            
            <input type = "file" name = "file" value={this.state.selectedFileName} onChange={this.selectedFileOnChange}/>
            <button onClick= {this.uploadFile}>Upload</button>
            <button onClick= {this.deleteFile}>Delete file</button></div>)
    }
}
FileUpload.contextType = GeneralContext
export default FileUpload