import AddButton from "./AddButton"
import TimeSlot from "./TimeSlot"

import styles from './DayModule.module.css'
import { Component } from "react";
import React, { useState } from 'react';

class HalfDay extends Component{
    constructor(props){
        super(props);
    };
        

    render() {

        var tooltip=null
        if (this.props.tooltipText){
            tooltip=<div className={styles.tooltipdiv}><span className={styles.tooltipbutton}>?</span><span className={styles.tooltipcontent}>{this.props.tooltipText}</span></div>
        }
 
        
        var valuesDisplayed= <div> 
        <ul className = {styles.halfDayTimeSlotsStyle}>
        {this.props.slotsProps.map(props => 
        <li key={props.id}> 
            <TimeSlot disabled={this.props.disabled} closeTimeSlot={this.props.slotRemoverFactory(props.id)}
            stateChangerFactory= {this.props.slotStateChangerFactory.bind(null, props.id)}
            heures={props.heures}
            quart={props.quart}
            chantier={props.chantier}
            code_chantier={props.code_chantier} /> </li>)}
        </ul>
        <AddButton disabled={this.props.disabled} onclickfunc={this.props.addSlot}/>
    </div>



        if (this.props.hasDisplayCheckbox){
            var checkbox= <input disabled={this.props.disabled} type="checkbox" name="displayValues" value={this.props.displayValues} checked={this.props.displayValues} onChange={this.props.displayOnChange}/>
            if (this.props.displayValues){
                var values=valuesDisplayed
            }
            else{
                var values=null
            }
        }
        else{
            var checkbox=null
            var values=valuesDisplayed
        }

        return(
            <div className={styles.halfDayStyle}>
                <div className={styles.horizontalFlex}>
                    <p className={styles.halfDayTitle}>{this.props.label}</p>
                    {checkbox}
                    {tooltip}
                </div>
                {values}
            </div>
        )
    };
}

export default HalfDay;