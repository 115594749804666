import { Component } from "react";

class Compte extends Component{


    render(){
        console.log('COmpte:')
        console.log(this.props.compte)
        var inputs=null
        if (this.props.compte.type=="compte"){
            console.log('type compte')
            inputs=
            <div>
                <label> Numero compte</label>
                <input type='number' value={this.props.compte.numero} onChange={this.props.setNumeroCompte}/>
                </div>
        }
        else if (this.props.compte.type=="plage"){
            console.log('type plage')
            inputs=
            <div>
                <label> Debut et fin comptes</label>
                <input type='number' value={this.props.compte.debut} onChange={this.props.setDebutPlage}/>
                <input type='number' value={this.props.compte.fin} onChange={this.props.setFinPlage}/>
            </div>
        }
        return(inputs)
    }
}

export default Compte