import { Component } from "react";
import { patchEmploye, getEmploye} from "./adminComms";
import PersonSelector from "../PersonSelector"

class PersonneInfo{
    constructor(id, prenom, nom, interim){
        this.id=id
        this.nom=nom
        this.prenom= prenom
    }
    getStringRep(){
        return(this.prenom + ' '+ this.nom + ' (' + String(this.id)+  ')')
    }
}


export default class EmployeDetails extends Component{
    constructor(props){
        super(props);
        this.state={}
        this.state.prenom=''
        this.state.nom=''
        this.state.type=''
        this.state.cache_dans_listes=false

    }

    async refreshEmploye(){
        var response = await getEmploye(this.props.idEmploye,this.props.context)
        if (response.status== 200){
            if (response.json!==null){
                var employeJson= response.json
                this.setState((state, props) => {
                    var newstate= {}
                    newstate.nom=employeJson.nom
                    newstate.prenom= employeJson.prenom
                    newstate.type= employeJson.type
                    newstate.cache_dans_listes=employeJson.cache_dans_listes
                    console.log('AAAAAAAAAAAAAAAAAAAAAAAA'+newstate.type)
                    return(newstate)});
            }
            else{
                throw new Error('Exception message');
            }
        }
        else{
            console.log("not good2")
            throw new Error('Exception message');
        }
    }

    prenomOnChange= (event) =>{
        this.setState({prenom:event.target.value})
    }

    nomOnChange= (event) =>{
        this.setState({nom:event.target.value})
    }

    typeOnChange= (event) =>{
        this.setState({type:event.target.value})
    }

    cacheOnChange= (event) =>{
        this.setState({cache_dans_listes:event.target.checked})
    }

    savePrenom= ()=>{
        patchEmploye(this.props.idEmploye,'prenom', this.state.prenom, this.props.context)
        this.refresh()
    }

    saveNom= ()=>{
        patchEmploye(this.props.idEmploye,'nom', this.state.nom, this.props.context)
        this.refresh()
    }

    saveType= ()=>{
        patchEmploye(this.props.idEmploye,'type', this.state.type, this.props.context)
        this.refresh()
    }

    saveCache= ()=>{
        patchEmploye(this.props.idEmploye,'cache_dans_listes', this.state.cache_dans_listes, this.props.context)
        this.refresh()
    }


    refresh= () =>{
        this.refreshEmploye()
    }

    componentDidMount(){
        this.refresh()
    }


    
    close=()=>{
        this.props.close()
    }

    render(){
        return(
        <div>
            <button onClick={this.close}>X</button>
            <button onClick={this.refresh}>RAFRAICHIR</button>
            <div>
                <label htmlFor="Nom">Nom</label>
                <input type="text" name="nom" value={this.state.nom} onChange={this.nomOnChange}/>
                <button onClick={this.saveNom}>Sauvegarder nom</button>
            </div>
            <div>
                <label htmlFor="prenom">Prenom</label>
                <input type="text" name="prenom" value={this.state.prenom} onChange={this.prenomOnChange}/>
                <button onClick={this.savePrenom}>Sauvegarder prenom</button>
            </div>
            <div>
                <label htmlFor="cacheinput">Cacher personne dans les listes (hors administration)? Utile si ancien employe par ex.</label>
                <input type="checkbox" name="cacheinput" checked={this.state.cache_dans_listes} onChange={this.cacheOnChange}/>
                <button onClick={this.saveCache}>Sauvegarder statut caché</button>
            </div>
            <div>
                <label htmlFor="typeinput">Choisir le type d'employe (different du type de compte)</label>
                <select name="typeinput" value={this.state.type} onChange={this.typeOnChange}>
                    {this.props.typeslist.map(([desc,type]) => {return(<option value={type} key={type}> {desc} </option>)})}
                </select>
                <button onClick={this.saveType}>Sauvegarder Type Employé</button>
            </div>
            
        </div>
        )
    }
}