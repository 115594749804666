import { getBDDFile} from "./bddcomms"
import { Component } from "react";
import GeneralContext from "../general-context";
import {LoadButton} from "../utils.js"

class BDD extends Component{
    constructor(props){
        super(props);
    }

    getBDD= async ()=> {
        const response= await getBDDFile(this.context)
        return response
    }


    render(){
        return(
            <div>
                <LoadButton onClick= {this.getBDD} text={"Get BDD"}/>
            </div>
        )
    }
}

BDD.contextType= GeneralContext
export default BDD

