export async function postFile(filestr,file,context){
    /*console.log("YEAR ET MONTH DANS POSTCR")
    console.log(year)
    console.log(month)*/
    var targetURL = new URL(context.baseURL + "/gestion_file_upload")
    var paramsString = new URLSearchParams({filestr:filestr}).toString()
    targetURL.search = paramsString
    console.log("file in postcr")
    console.log(file)
    const payload=file
    var response = await context.jsonFetcher(targetURL, payload, 'POST')
    return response
}

export async function getFileStatus(filestr,context){
    var targetURL = new URL(context.baseURL + "/gestion_file_upload")
    var paramsString = new URLSearchParams({filestr:filestr}).toString()
    targetURL.search = paramsString
    var response = await context.jsonFetcher(targetURL, null, 'GET')
    return response
}

export async function getGestion(context, endDateStr){
    var targetURL = new URL(context.baseURL + "/gestion")
    var paramsString=null
    if (endDateStr!==null && endDateStr!==undefined){
        paramsString = new URLSearchParams({endDateStr:endDateStr}).toString()
    }
    else{
        paramsString = new URLSearchParams({}).toString()
    }
    
    targetURL.search = paramsString
    var response = await context.fileFetcher(targetURL, null, 'GET', "gestion.xlsx")
    return response
}

export async function getCheck(context, startCheckDate){
    var targetURL = new URL(context.baseURL + "/checkCodesHeures")
    var paramsString=null
    paramsString = new URLSearchParams({startCheckDate:startCheckDate}).toString()

    targetURL.search = paramsString
    var response = await await context.jsonFetcher(targetURL, null, 'GET')
    return response
}