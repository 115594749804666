import { useHistory } from "react-router-dom";
import genstyles from "./components.module.css"

function MenuButton (props){
    
    let history = useHistory()
    function onClick(){
        history.push('/')
    };

    return(<button className={genstyles.button} onClick={onClick}>Menu</button>);
}


export default MenuButton