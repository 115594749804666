import styles from './DayModule.module.css'
import { Component } from "react";

class StatusDisplay extends Component{

    constructor(props){
        super(props);
    };

    render(){
        var statusColor=styles.statusDefault
        var statusText=''
        if (this.props.status=='default'){
            statusColor=styles.statusUnmodified
            statusText='en attente'
        }
        else if (this.props.status=='no_day_found'){
            statusColor=styles.statusUnmodified
            statusText='Jour vide chargé'
        }
        else if(this.props.status=='unmodified_day'){
            statusColor=styles.statusUnmodified
            statusText='Jour chargé'
            if (this.props.bloque){
                statusText= statusText+ ' (bloqué!)'
            }
        }
        else if(this.props.status=='modified_day'){
            statusColor=styles.statusModified
            statusText='Jour modifié'
        }
        else if(this.props.status=='saved_day'){
            statusColor=styles.statusSaved
            statusText='Jour sauvegardé'
        }
        else if(this.props.status=='error_save_day'){
            statusColor=styles.statusSaveError
            statusText='Erreur sauvegarde'
        }
        else{
            console.log('Unrecognized status')
        }
        var statusStyle= styles.statusDisplay + ' ' + statusColor
        
        return(
        <div className={statusStyle}>
            {statusText}
        </div>)};

}
export default StatusDisplay