import { Component } from "react";
import { patchCompte } from "./adminComms";

export default class PasswordChanger extends Component{
    constructor(props){
        super(props);
        this.state={}
        this.state.pwd=''
        this.state.confirm=''
    }

    savePwd= async ()=>{
        if (this.state.pwd!=this.state.confirm){
            window.alert('Mot de passe et confirmation différents!')
        }
        else{
            await patchCompte(this.props.username, 'password', this.state.pwd, this.props.context)
            window.alert('Mot de passe changé!')
        }
    }

    pwdOnChange= (event)=>{
        this.setState({pwd:event.target.value})
    }
    confirmOnChange= (event)=>{
        this.setState({confirm:event.target.value})
    }

    render(){
        return(
        <div>
        <label htmlFor="pwdinput">Nouveau mot de passe</label>
        <input type="password" name="pwdinput" onChange={this.pwdOnChange}/>
        <label htmlFor="confirminput">Confirmer le mot de passe</label>
        <input type="password" name="confirminput" onChange={this.confirmOnChange}/>
        <button onClick={this.savePwd}>Sauvegarder mot de passe</button>
        </div>
        )
    }
}