import { Component } from "react";

class ListDisplay extends Component{

    constructor(props){
        super(props);
    }

    render(){

        return(<ol>
            {this.props.list.map((element,index) =>
                <li key={index} value={index} onClick={this.props.changePickedIndex}>{element[this.props.titleKeyword]} </li>
                )}
            </ol>
        )
    }
}

export default ListDisplay