import { Component } from "react";
import React, { useState } from 'react';
import {GeneralContext} from '../general-context'
import styles from './DayModule.module.css'
import genstyles from "../components.module.css"

class MidDay extends Component{

    static contextType = GeneralContext

    constructor(props){
        super(props);
        this.state = {slots: [], nextID: 0, saveSignal: 0};
    };

    componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps.saveSignal != this.props.saveSignal) {
            this.saveMidDay()
        }
    }

    saveMidDay(){
        console.log('aaa')
    }

    render(){
    return(<div className={styles.halfDayStyle}>
        <div className={styles.horizontalFlex}>
            <div className={styles.halfDayTitle}>Midi</div>
            <label className={styles.midDayMargins}> Panier repas <input disabled={this.props.disabled} type="checkbox" name="Panier repas" checked={this.props.panier_repas.value} onChange={this.props.panier_repas.onChange}/> </label>
        </div>
        </div>);
    }
}


export default MidDay