import styles from './DayModule.module.css'
import genstyles from "../components.module.css"
import { Component } from "react";
import React, { useState } from 'react';
import {GeneralContext} from '../general-context' 

class TimeSlot extends Component{

    static contextType = GeneralContext

    constructor(props){
        super(props);
    };

    render(){
        return(
        <div className={styles.timeSlotStyle}>
            <fieldset className={styles.timeSlotFieldsStyle}>
                <div className={styles.horizontalFlex}>
                    <input disabled={this.props.disabled} className={styles.timeInput+ ' '+genstyles.blueInput} id="heuresInput" type="number" name="heures" value={this.props.heures} 
                            onChange={this.props.stateChangerFactory('heures')} maxLength="2" required/>
                    <label className={styles.dayLabelStyle} htmlFor="heuresInput">h</label>

                    <select disabled={this.props.disabled} className={styles.timeInput+ ' '+genstyles.blueInput} id="minutesInput" type="number" list="quarts" name="quart" 
                    onChange={this.props.stateChangerFactory('quart')}
                    value={this.props.quart}>
                        <option value="0">0</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                    </select>
                    <label className={styles.dayLabelStyle} htmlFor="minutesInput">min</label>
                </div>

                <div className={styles.horizontalFlex}>
                    <label htmlFor='codeChantierInput'>Code chantier: </label>
                    <input disabled={this.props.disabled} className={styles.codeChantier+ ' '+genstyles.blueInput} type="text" name="code chantier" id="codeChantierInput" value={this.props.code_chantier}
                        onChange={this.props.stateChangerFactory('code_chantier')} maxLength="7" required/>
                </div>
            </fieldset>
            <button disabled={this.props.disabled} className={styles.button} type="button" onClick= {this.props.closeTimeSlot}> X </button>
        </div>)};

}
TimeSlot.contextType = GeneralContext
export default TimeSlot