import { Component } from "react";


export default class Adder extends Component{
    constructor(props){
        super(props);
        var fields= props.fields
        var fieldvalues= {}
        for (const [field, fielddict] of fields) {
            if (fielddict.type=='text' || fielddict.type=='password'){
                var defaultValue=''
            }
            else if (fielddict.type=='number'){
                var defaultValue=0
            }
            else if (fielddict.type=='checkbox'){
                var defaultValue=false
            }
            else if (fielddict.type=='select'){
                var defaultValue=fielddict.selectlist[0][1]
            }
            fieldvalues[field]=defaultValue
          }
        this.state={}
        this.state.fieldvalues=fieldvalues
    }

    changeFieldValue(field,value){
        this.setState((state,props)=> {
            var newstate={}
            newstate.fieldvalues= {...state.fieldvalues}
            newstate.fieldvalues[field]=value
            return newstate
        })
    }

    saveElement= ()=>{
        var values={...this.state.fieldvalues}
        for (const [field, value] of Object.entries(this.props.preloaded)) {
            values[field]=value
        }
        var success= this.props.onConfirm(values)
        if (success){
            this.close()
        }
        else{
            window.alert("Erreur de sauvegarde, veuillez réessayer");
        }
    }

    close=()=>{
        this.props.onClose()
    }

    onValueChange(field,event){
        this.changeFieldValue(field,event.target.value)
    }

    makeField= (field,fielddict)=>{
        if (fielddict.type==='select'){
            return(
                <div>
                <p>{field}</p>
                <select name={field} value={this.state.fieldvalues[field]} onChange={this.onValueChange.bind(this,field)}>
                    {fielddict.selectlist.map(([desc,type]) => {return(<option value={type} key={type}> {desc} </option>)})}
                </select>
                </div>
            )
        }
        else{
            console.log('rendering non select field')
            console.log(field)
            console.log(fielddict)
            const element=<div>
            <p>{field}</p>
            <input name={field} type={fielddict.type} value={this.state.fieldvalues[field]} onChange={this.onValueChange.bind(this,field)}/>
            </div>
            console.log(element)
            return(element)
        }           
    }

    render(){
        console.log(this.props.fields)
        return(
            <div>
                <button onClick={this.close}>Retour</button>
                <h1>{this.props.title}</h1>
                {this.props.fields.map(([field,fielddict])=>{return(this.makeField(field,fielddict))})}
                    
                <button onClick={this.saveElement}>Sauvegarder</button>
            </div>

        )
    }
}