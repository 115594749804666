import { Component } from "react";
import React, { useState } from 'react';
import ListDisplay from './ListDisplay.jsx'
import ListeComptes from './ListeComptes.jsx'
import {getCfg} from '../tableaubordComms.js'
import GeneralContext from '../../general-context'
class CfgDisplay extends Component{

    constructor(props){
        super(props);
        this.state = {"categories":[], pickedCategorieIndex:null, pickedLigneIndex:null}
    }

    async getTableauCfg(){
        const response=await getCfg(this.context)
        this.setState({categories:response.json.categories})
    }

    ajouterLigne= (catindex,ligneTitle)=>{
        this.setState((oldstate, oldprops)=>{
            const copyCategories= JSON.parse(JSON.stringify(oldstate.categories))
            const modifiedCat= copyCategories[catindex]
            modifiedCat.lignes.push({'title':ligneTitle, 'comptes': []})
            return ({'categories':copyCategories})
        })
    }
    ajouterCompte= (catindex, ligneindex)=>{
        this.setState((oldstate, oldprops)=>{
            const copyCategories= JSON.parse(JSON.stringify(oldstate.categories))
            const modifiedCat= copyCategories[catindex]
            const modifiedLigne= modifiedCat.lignes[ligneindex]
            modifiedLigne.comptes.push({'type':'plage','debut':null,'fin': null})
            return ({'categories':copyCategories})
        })
    }
    changeCompteType= ()=>{
        
    }
    setNumeroCompte= (catindex, ligneindex,compteindex, event)=>{
        const numerocompte= event.target.value
        this.setState((oldstate, oldprops)=>{
            const copyCategories= JSON.parse(JSON.stringify(oldstate.categories))
            const modifiedCat= copyCategories[catindex]
            const modifiedLigne= modifiedCat.lignes[ligneindex]
            const modifiedCompte= modifiedLigne.comptes[compteindex]
            modifiedCompte.numero= numerocompte
            return ({'categories':copyCategories})
        })
    }
    setDebutPlage= (catindex, ligneindex,compteindex, event)=>{
        const debutplage= event.target.value
        this.setState((oldstate, oldprops)=>{
            const copyCategories= JSON.parse(JSON.stringify(oldstate.categories))
            const modifiedCat= copyCategories[catindex]
            const modifiedLigne= modifiedCat.lignes[ligneindex]
            const modifiedCompte= modifiedLigne.comptes[compteindex]
            modifiedCompte.debut= debutplage
            return ({'categories':copyCategories})
        })
    }
    setFinPlage=(catindex, ligneindex,compteindex, event)=>{
        const finplage= event.target.value
        this.setState((oldstate, oldprops)=>{
            const copyCategories= JSON.parse(JSON.stringify(oldstate.categories))
            const modifiedCat= copyCategories[catindex]
            const modifiedLigne= modifiedCat.lignes[ligneindex]
            const modifiedCompte= modifiedLigne.comptes[compteindex]
            modifiedCompte.fin= finplage
            return ({'categories':copyCategories})
        })

    }
    changePickedCategorie=(event)=>{
        console.log('In ChangePickedCategorie')
        console.log(event.target)
        console.log(event.target.value)
        this.setState({pickedCategorieIndex:event.target.value,pickedLigneIndex:null})
    }

    changePickedLigne=(event)=>{
        this.setState({pickedLigneIndex:event.target.value})
    }

    componentDidMount(){
        this.getTableauCfg()
    }

    render(){
        var lignes=null
        var comptes=null

        

        const pickedCategorieIndex=this.state.pickedCategorieIndex
        const pickedLigneIndex= this.state.pickedLigneIndex

        const setNumeroCompteB= (indexCompte, event)=>{this.setNumeroCompte(pickedCategorieIndex, pickedLigneIndex,indexCompte, event)}
        const setDebutPlageB= (indexCompte, event)=>{this.setDebutPlage(pickedCategorieIndex, pickedLigneIndex,indexCompte, event)}
        const setFinPlageB= (indexCompte, event)=>{this.setFinPlage(pickedCategorieIndex, pickedLigneIndex,indexCompte, event)}
        if (this.state.pickedLigneIndex!==null){
            comptes=<ListeComptes listeComptes={this.state.categories[pickedCategorieIndex].lignes[pickedLigneIndex].comptes} 
                setNumeroCompte={setNumeroCompteB} setDebutPlage={setDebutPlageB} setFinPlage={setFinPlageB}/>
        }
        if (this.state.pickedCategorieIndex!==null){
            console.log('lignes1')
            console.log(this.state.categories[pickedCategorieIndex].lignes)
            lignes= <ListDisplay list={this.state.categories[pickedCategorieIndex].lignes} titleKeyword='titre' pickedIndex={this.state.pickedCategorieIndex} changePickedIndex={this.changePickedLigne}/>
        }
        console.log('lignes2')
        console.log(this.state.categories)
        return(
            <div>
                <div>
                    <h1>Categories</h1>
                    <ListDisplay list={this.state.categories} titleKeyword='titre' pickedIndex={this.state.pickedLigneIndex} changePickedIndex={this.changePickedCategorie}
                    />
                </div>
                <div>
                    <h1>Lignes</h1>
                    {lignes}
                </div>
                <div>
                    <h1>Comptes</h1>
                    {comptes}
                </div>
            </div>
        )
    }
}

export default CfgDisplay
CfgDisplay.contextType = GeneralContext