import { Component } from "react";
import {PersonInfo} from "../utils";
import styles from './viz.module.css'

export default class TableDisplay extends Component {

    constructor(props){
        super(props);
        this.state={}
        this.state.colCollapse=false
        this.state.rowCollapse=false
    }
    
    onRowCollapseChange= (event)=>{
        console.log('rowcollapsechange')
        this.setState({rowCollapse:event.target.checked})
    }

    onColCollapseChange= (event)=>{
        console.log('colcollapsechange')
        this.setState({colCollapse:event.target.checked})
    }

    render(){
        var boxes=null
        if (this.props.canCollapse){
            boxes=  <div className={styles.collapseButtonsDiv}>
                        <label htmlFor="rowCollapseCB"> Réduire chantiers (ne laisse que ligne total) </label>
                        <input  type="checkbox" name='rowCollapseCB' onChange={this.onRowCollapseChange} checked={this.state.rowCollapse}/>
                        <label htmlFor="colCollapseCB"> Réduire employés (ne laisse que colonne total)</label>
                        <input  type="checkbox" name="colCollapseCB" onChange={this.onColCollapseChange} checked={this.state.colCollapse}/>
                    </div>
        }

        const collapsedColList= (row)=>{
            return([<td className={styles.tabletdtr}>{row[0]}</td>, <td className={styles.tabletdtr}>{row[row.length-1]}</td>])
        }
        const fullColList= (row)=>{
            return(row.map(cell=>{return(<td className={styles.tabletdtr}>{cell}</td>)}))
        }

        var colListMaker=fullColList
        if(this.state.colCollapse){
            colListMaker=collapsedColList
        }


        var rowarray=[]
        if(this.state.rowCollapse){
            rowarray.push(colListMaker(this.props.table[0]))
            rowarray.push(colListMaker(this.props.table[this.props.table.length-1]))
        }
        else{
            
            for (var row of this.props.table){
                rowarray.push(colListMaker(row))
            }   
        }
        const disptable= <table className={styles.table}><tbody>{rowarray.map(row=>{return(<tr className={styles.tabletdtr}>{row}</tr>)})}</tbody></table>
        return(
            <div>
                {boxes}
                {disptable}
            </div>)
    }
}