import { Component } from "react";
import styles from "./AdminStyles.module.css"

export default class VerticalGroup extends Component{

    render(){
        return(
        <div className={styles.vertgroup}>
            <div className={styles.grouptitlediv}>
                {this.props.title}
            </div>
            <div className={styles.groupdiv}>
                {this.props.children}
            </div>
        </div>
        )
    }
}