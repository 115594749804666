export async function postCR(month,year,file,context){
    /*console.log("YEAR ET MONTH DANS POSTCR")
    console.log(year)
    console.log(month)*/
    var targetURL = new URL(context.baseURL + "/cr_upload")
    var paramsString = new URLSearchParams({month: month, year:year}).toString()
    targetURL.search = paramsString
    console.log("file in postcr")
    console.log(file)
    const payload=file
    var response = await context.jsonFetcher(targetURL, payload, 'POST')
    return response
}


export async function deleteCR(month,year,context){
    var targetURL = new URL(context.baseURL + "/cr_upload")
    var paramsString = new URLSearchParams({month: month, year:year}).toString()
    targetURL.search = paramsString
    var response = await context.jsonFetcher(targetURL, null, 'DELETE')
    return response
}
export async function getCRStatus(month,year,context){
    var targetURL = new URL(context.baseURL + "/cr_upload")
    var paramsString = new URLSearchParams({month: month, year:year}).toString()
    targetURL.search = paramsString
    var response = await context.jsonFetcher(targetURL, null, 'GET')
    return response
}

export async function putCfg(cfgDict,context){
    var targetURL = new URL(context.baseURL + "/tableaubord_cfg")
    var payload=JSON.stringify(cfgDict)
    var response = await context.jsonFetcher(targetURL, payload, 'PUT')
    return response
}

export async function getCfg(context){
    var targetURL = new URL(context.baseURL + "/tableaubord_cfg")
    const payload=null
    var response = await context.jsonFetcher(targetURL, payload, 'GET')
    return response
}

export async function getTableauBord(startmonth,year,context){
    var targetURL = new URL(context.baseURL + "/tableau_bord")
    var paramsString = new URLSearchParams({startmonth: startmonth, year:year}).toString()
    targetURL.search = paramsString
    var response = await context.fileFetcher(targetURL, null, 'GET', "tableaubord.xlsx")
    return response
}