import styles from './DayModule.module.css'
import genstyles from "../components.module.css"

function DayToggle(props) {
    return (
    <div className={styles.dayDivElementStyle}>
        <label htmlFor={props.name} className={styles.dayLabelStyle}> {props.label} </label>
        <input disabled={props.disabled} className={styles.dayInputStyle}  type="checkbox" name={props.name} required
            onChange={props.onChange} checked={props.value}/>
    </div>
        )
}
export default DayToggle;