import { Component } from "react";
import {PersonInfo} from "../utils";
import CheckboxSelector from "./CheckboxSelector"
import MultiSelector from "./MultiSelector"
import GeneralContext from "../general-context";
import TableDisplay from "./TableDisplay"
import styles from "./viz.module.css"
import genStyles from "../components.module.css"
import VerticalGroup from "./VerticalGroup";


export default class Viz extends Component {
    constructor(props){
        super(props);
        this.state={}
        this.state.allPersons={}
        this.state.pickedPersons={}
        this.state.pickedChantiers=[]
        this.state.responseDisplay=null
        this.state.setDates=false
        //var startDateStr= new Date.toISOString().substring(0, 10);
        this.state.startDateStr=null
        this.state.endDateStr=null
    }

    setAllPersons(dict){
        this.setState({allPersons:dict})
    }

    personDictSetter(persons){
        var personDict= {}
        for (let p of persons){
            var pInfo= new PersonInfo(p.id, p.prenom, p.nom)
            personDict[pInfo.id]= pInfo
        }
        this.setAllPersons(personDict)
    }

    async loadPersonList(){
        var response = await this.getPersonList()
        if (response.status== 200){
            if (response.json!==null){
                this.personDictSetter(response.json,this.setPersonDict)
            }
            else{
                throw new Error('Exception message');
            }
        }
        else{
            console.log("not good")
            throw new Error('Exception message');
        }

    }

    async getPersonList(){
        const selfID= this.context.id_employe
        console.log(this.context.baseURL)
        var targetURL = new URL(this.context.baseURL + "/employe")
        var paramsString = new URLSearchParams({get_all: true}).toString()
        targetURL.search = paramsString
        var response = await this.context.jsonFetcher(targetURL, null, 'GET')
        return response
    }

    addPerson= (personid)=>{
        this.setState((state, props) => {
            var newState={'pickedPersons':state.pickedPersons}
            newState['pickedPersons'][personid]=personid
            return newState;
          });
        console.log('in addPerson')
    }

    removePerson=(personId)=>{
        this.setState((state, props) => {
            var pp=state.pickedPersons
            delete pp[personId]
            const newState={pickedPersons:pp}
            return newState;
          });
    }


    checkAll=()=>{
        this.setState((state, props) => {
            const allPersons= state.allPersons
            var newPickedPersons={}
            for (let id in allPersons){
                var person=allPersons[id]
                newPickedPersons[person.id]=person
            }
            return {pickedPersons:newPickedPersons}
        })
    }

    uncheckAll=()=>{
        this.setState({pickedPersons:{}})
    }
    addChantier=(codeChantier)=>{
        console.log('addChantier')
        this.setState((state, props) => {
            console.log('in setstate')
            var newState={'pickedChantiers':state.pickedChantiers}
            newState['pickedChantiers'].push(codeChantier)
            console.log(newState)
            return newState;
          });
    }

    removeChantier=(codeChantier)=>{
        this.setState((state, props) => {
            var pp=state.pickedChantiers
            const chindex=pp.indexOf(codeChantier)
            pp.splice(chindex,1)
            const newState={pickedChantiers:pp}
            return newState;
          });
    }

    componentDidMount(){
        this.loadPersonList()
    }

    retrieveHours= async()=>{
        const employes= Object.keys(this.state.pickedPersons)
        const chantiers= this.state.pickedChantiers
        const startDateStr= this.state.startDateStr
        const endDateStr= this.state.endDateStr
        const targetURL = new URL(this.context.baseURL + "/processed_sessions")
        const sessions= {"id_employes":employes, "codes_chantier":chantiers, "date_debut":startDateStr, "date_fin": endDateStr}
        const payload=JSON.stringify(sessions)
        const response = await this.context.jsonFetcher(targetURL, payload, 'POST')
        return response
    }


    makeTable= (perChantier,employes,chantiers, addTotals=false)=>{

        var table=[[""]]
        for (var employe of employes){
            table[0].push(this.state.allPersons[employe].nom)}
        for (var chantier of chantiers){
            var row=[chantier]
            var subdict=perChantier[chantier]
            for (var employe of employes){
                var hours=0
                if (employe in subdict){
                    hours=subdict[employe]
                }
                row.push(hours)
            }
            table.push(row)
        }

        if (addTotals){
            var height= table.length
            var width= table[0].length
            table[0].push("TOTAL")
            for (let  j=1;j<height;j++){
                var row=table[j]
                var total=0
                for (let  i=1;i<width;i++){
                    var value= row[i]
                    total=total + parseFloat(value)
                }
                console.log(total)
                row.push(total)
            }
            //colonnes
            var height= table.length
            var width= table[0].length
            console.log(width)
            var colTotals= ["TOTAL"]
            table.push(colTotals)
            for (let  i=1;i<width;i++){
                var total=0
                for (let  j=1;j<height;j++){
                    var value= table[j][i]
                    total=total + parseFloat(value)
                }
                console.log(total)
                colTotals[i]=total

            }
        }
        return table

    }

    makeSessionsTable= (sessions)=>{
        const titles= ['date','heures','employé','code chantier']
        const keys=['date','heures','employestr','code_chantier']
        var table=[titles]
        for (var session of sessions){
            var row=[]
            for (var key of keys){
                row.push(session[key])
            }
            table.push(row)
        }
        return(table)
    }
    doQuery= async ()=>{
        const response = await this.retrieveHours()
        console.log(response)
        if (response.status==200){
            const resjson=response.json
            const table = this.makeTable(resjson.perchantier,resjson.present.employes,resjson.present.chantiers,true)
            console.log(table)
            const sessionstable= this.makeSessionsTable(resjson.sessions)
            this.setState({"responseDisplay":<div className={styles.responseElement}><TableDisplay table={table} canCollapse={true}/><h2>Details des sessions travail</h2><TableDisplay table={sessionstable} canCollapse={false}/></div>})
        }
    }


    onStartDateChange=(event)=>{
        this.setState({'startDateStr': event.target.value})
    }
    onEndDateChange=(event)=>{
        this.setState({'endDateStr': event.target.value})
    }
    onSetDatesChange= (event)=>{
        if(event.target.checked){
            const date=new Date().toISOString()
            this.setState({'setDates':true,'startDateStr': date.substring(0, 10), 'endDateStr': date.substring(0, 10)})
        }
        else{
            this.setState({'setDates':false,'startDateStr':null, 'endDateStr':null})
        }
    }


    render(){
        var datessetter=null
        if (this.state.setDates){
            console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
            datessetter=<div>
                <label htmlFor="start">Date départ</label>
                <input className={genStyles.blueInput} type='date' id='start' value={this.state.startDateStr} onChange={this.onStartDateChange}/>
                <label htmlFor="end">Date fin</label>
                <input className={genStyles.blueInput} type='date' id='end' value={this.state.endDateStr} onChange={this.onEndDateChange}/>
            </div>
        }
        return(
            <div>
                <h2>Si aucun élément d'une catégorie n'est sélectionné, la recherche se fera sans filtrer les éléments de cette catégorie</h2>
                <div className={styles.horizVertGroups}>
                    <VerticalGroup title='Selection par date (inclus)'>
                        <div>
                            <label htmlFor='setDatesCB'>Activer sélection par date</label>
                            <input type="checkbox" id='setDatesCB' checked={this.state.setDates} onChange={this.onSetDatesChange}/>
                        </div>
                        {datessetter}
                    </VerticalGroup>
                    <VerticalGroup title='Selection par personne'>
                        <CheckboxSelector listedElements={this.state.allPersons} pickedElements={this.state.pickedPersons} addElement={this.addPerson} 
                        removeElement={this.removePerson} checkAll={this.checkAll} uncheckAll={this.uncheckAll}/>
                    </VerticalGroup>
                    <VerticalGroup title='Selection par chantier (+ pour ajouter)'>
                        <MultiSelector pickedElements={this.state.pickedChantiers} addElement={this.addChantier} removeElement={this.removeChantier}/>
                    </VerticalGroup>
                </div> 
                
                <button className={genStyles.bigwhiteButton} onClick={this.doQuery}>Lancer Requête</button>
                
                {this.state.responseDisplay}
            </div>

            
        )
    }
}
Viz.contextType = GeneralContext