import { useHistory } from "react-router-dom";
import style from './DashButton.module.css';

function DashButton (props){
    let history = useHistory()

    function onClick(){
        console.log('test')
        history.push(props.target)
    };

    return(
    <div>
        <button className = {style.dashButton} hover={style.hover} active={style.active} onClick={onClick}>
            {props.text}
        </button>
    </div>);
}

export default DashButton;