import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import logo from './e6logo.jpg';
import './App.css';
import MenuButton from './components/MenuButton'

import Login from './components/login/Login'
import Dashboard from './components/dashboard/Dashboard'
import makeFetchFunction from './components/comms'
import TestComp from './components/TestComp'
import Day from './components/day/Day'
import DayArray from './components/day/DayArray'
import AdminComponent from './components/admin/AdminComponent';
import Viz from './components/viz/Viz';
import RecapArray from './components/recap/RecapArray';
import TableauBordMain from './components/tableaubord/TableauBordMain.jsx'
import BDD from './components/bdd/bdd.jsx'
import ManagerHeures from './components/managerheures/managerheures';
import Gestion from './components/gestion/gestion';
import TxCours from  './components/travauxcours/TravauxCours.jsx';
import {GeneralContext, initGeneralContext} from './components/general-context'


function App() {

  const [loginJson, setLoginJson] = useState();
  const [savedDay, setSavedDay]= useState();
  const apiURL=process.env.REACT_APP_API_URL

  const head=<header className="App-header">
              <MenuButton/>
              <img src={logo} className="App-logo" alt="logo" />
              <h1>E6TEM INTRANET</h1>
            </header>

  if(!loginJson) {
    return (
    <div className="App">
    {head}
      <div className="Main-page">
        <Login setLoginJson={setLoginJson} apiURL={apiURL} />
      </div>
    </div>)
  }

  

  const tokContextValue = initGeneralContext(loginJson.access_token, loginJson.user_prenom, loginJson.user_nom, loginJson.user_id_personne,loginJson.type_compte,loginJson.params, apiURL, savedDay, setSavedDay)
  console.log(tokContextValue)

  return (
    <div className="App">
      <BrowserRouter>
      {head}
      <div className="Main-page">
        <GeneralContext.Provider value={tokContextValue}>
            {/*first match stops the search, order matters*/}
            <Switch>
                <Route path="/saisiesemaine">
                  <DayArray arraySize={7} onlyLundis={true}/> {/* a changer*/}
                </Route>
                <Route path="/saisiejour">
                  <DayArray arraySize={1} /> {/* a changer*/}
                </Route>
                <Route path="/admin">
                  <AdminComponent/> {/* a changer*/}
                </Route>
                <Route path="/recherche">
                  <Viz/> {/* a changer*/}
                </Route>
                <Route path="/recap">
                  <RecapArray arraySize={7} onlyLundis={true}/> {/* a changer*/}
                </Route>
                <Route path="/tableaubord">
                  <TableauBordMain/> 
                </Route>
                <Route path="/bdd">
                  <BDD/> 
                </Route>
                <Route path="/managerheures">
                  <ManagerHeures/> 
                </Route>
                <Route path="/gestion">
                  <Gestion/> 
                </Route>
                <Route path="/travauxcours">
                  <TxCours/> 
                </Route>
                <Route path="/">
                  <Dashboard />
                </Route>
            </Switch>
        </GeneralContext.Provider>
      </div>
      </BrowserRouter>
    </div>
  );
};


export default App;
