import { Component } from "react";
import genStyles from "../components.module.css"

export default class CheckboxSelector extends Component {

    constructor(props){
        super(props);
        this.state = {newElement:""};
    };


    addElement=(event)=>{
        this.props.addElement(this.state.newElement)
        this.setState({"newElement":""})
    }

    removeElementFactory=(element)=>{
        const removeElement= (event)=>{
            this.props.removeElement(element)
        }
        return removeElement
    }

    inputOnChange=(event)=>{
        this.setState({newElement:event.target.value})
    }

    render(){
        return(
            <div>
                <input type="text" onChange={this.inputOnChange} value={this.state.newElement}/>
                <button className={genStyles.whiteButton} onClick={this.addElement}>+</button>
                {this.props.pickedElements.map(element => 
                    {return(<div><span>{element}</span>
                            <button className={genStyles.whiteButton} onClick={this.removeElementFactory(element)}>Retirer chantier</button></div>)})}
            </div>)
    }
}