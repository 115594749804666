import { Component } from "react";
import FileUpload from "./FileUpload.jsx"
import { getGestion, getCheck } from "./gestionComms.js";
import GeneralContext from "../general-context";
import styles from "../components.module.css"
import {LoadButton} from "../utils.js"

class Gestion extends Component{

    constructor(props){
        super(props);
        this.state={'useEndDate':false,'endDate':null, 'debutCheckHeuresDevis':null}
    }

    onUseEndDateChange= (event)=>{
        this.setState({'useEndDate':event.target.checked})
    }

    onEndDateChange= (event)=>{
        this.setState({'endDate':event.target.value})
    }

    onDebutCheckHeuresDevisChange= (event)=>{
        this.setState({'debutCheckHeuresDevis':event.target.value})
    }

    makeGestion= async ()=>{
        if (this.state.useEndDate){
            const response= await getGestion(this.context,this.state.endDate)
            return response
        }
        else{
            const response= await getGestion(this.context)
            return response
        }
    };

    checkCodesHeures= async()=>{
        
        const response= await getCheck(this.context,this.state.debutCheckHeuresDevis)
        return response

    };

    render(){
        var endDateInput= null
        if (this.state.useEndDate){
            endDateInput=<input type='date' id='endDate' value={this.state.endDate} onChange={this.onEndDateChange}/>
        };

        console.log(this.monthslist)
        console.log(this.props.year)
        return(<div >
            <p>Le fichier Devis ne doit avoir que les devis a prendre!
                Il doit contenir les colonnes 'Code Devis' et 'Information'
                Le fichier achats1 contient les achats recents
                Le fichier achats2 contient les achats d'avant
                Achats1 et Achats2 doivent contenir les colonnes 'Commentaire','Compte'
                et 'Montant' ET 'Sens' (montant etant un nombre positif, sens etant 1 ou -1)
                OU 'Solde' (nombre déjà positif ou négatif )
                Les fichiers doivent etre au format .xlsx
            </p>
            <LoadButton onClick={this.makeGestion} text={"Creer fichier gestion"}/>
            <FileUpload filestr="devis"/>
            <FileUpload filestr="achats1"/>
            <FileUpload filestr="achats2"/>
            <p>Utiliser une date butoir pour les heures employés (incluse)?</p>
            <input type="checkbox" id='useEndDate' checked={this.state.useEndDate} onChange={this.onUseEndDateChange}/>
            {endDateInput}

            <p>tester si les codes chantiers des heures existent dans le fichier devis ci-dessus, à partir de cette date</p>
            <input type='date' id='debutcheckheures' value={this.state.debutCheckHeuresDevis} onChange={this.onDebutCheckHeuresDevisChange}/>
            <button onClick={this.checkCodesHeures}>vérifier heures</button>
        </div>
        )
    }
}
Gestion.contextType = GeneralContext
export default Gestion