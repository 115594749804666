import GeneralContext from "../general-context";
import { Component } from "react";
import { getManagerHeuresFile } from "./managerheurescomms";
import { LoadButton} from "../utils.js"


class ManagerHeures extends Component{
    constructor(props){
        super(props);
        this.state={'annee':0,'mois':1}
    }


    anneeOnChange= (event)=>{
        const value= event.target.value
        this.setState({'annee':value})
    }

    moisOnChange= (event)=>{
        const value= event.target.value
        this.setState({'mois':value})
    }

    genererHeures= async()=>{
        const response= await getManagerHeuresFile(this.state.mois, this.state.annee, this.context)
        return response
    }
    render(){
        return(
        <div>
            <label htmlFor="anneeinput">Année debut</label>
            <input id="anneeinput" type="number" value={this.state.annee} onChange={this.anneeOnChange}></input>
            <label htmlFor="moisinput">Mois debut</label>
            <input id="moisinput" type="number" value={this.state.mois} onChange={this.moisOnChange}></input>
            <LoadButton onClick={this.genererHeures} text={"Générer heures par manager"}/>
        </div>)
    }
}




ManagerHeures.contextType= GeneralContext
export default ManagerHeures