export function makeFetchFunction(token){
    //Creates a fetch function that will incorporate the correct header token
    async function fetcher(target, body, method ){
        var response = await fetch(target, {headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}, body: body, method: method})
        //console.log("response status at fetch resolve: " + response.status)
        var text= await response.text()
        console.log("response text at fetch resolve: " + text)
        var resObject = {text: text , status: response.status}      
        return resObject
    }
    return fetcher
}


export function makeJsonFetchFunction(fetcher){
    async function jsonFetcher(target, jsonToSend, method ){
        var resObject= await fetcher(target, jsonToSend, method)
        var jsonResObject= {json: JSON.parse(resObject.text) , status: resObject.status}
        return jsonResObject 
    }
    return jsonFetcher
}


export function makeFileFetchFunction(token){
    async function fileFetcher(target, body, method, filename ){
        const response= await fetch(target, {headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}, body: body, method: method})
        
        if (response.status==200){
            const blob= await response.blob()
            console.log(blob)
            /*var file = new File([blob], "paies.xlsx")
            var fileURL = window.URL.createObjectURL(file)
            window.location.assign(fileURL)
            return 200*/
            // (C2) TO "FORCE DOWNLOAD"
            var url = window.URL.createObjectURL(blob),
            anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = filename;
            anchor.click();
        
            // (C3) CLEAN UP
            window.URL.revokeObjectURL(url);
            //document.removeChild(anchor);
            return response.status
        }
        else{
            const errorStr= await response.text()
            //console.log("responsebody")
            //console.log(response)
            window.alert(errorStr)
            return response.status
        }
        
         
    }
    return fileFetcher
}

export async function fetchHandling(fetchFunction, afterFunction){
    try{
        var response= await fetchFunction()
        
        console.log(response.status)

        if (response.status>=200 && response.status<300){
            afterFunction(response.body, response.status)
        }
        else{
            var errorStr= 'Erreur de récupération des données: status ' + String(response.status)
            window.alert(errorStr)
        }
    }
    catch(error){
        var errorStr= 'Erreur de communication avec la base de données: ' + String(error)
        window.alert(errorStr)
    }
    

}

const fetchfun= async ()=>{ 
    const response= await fetch('http://127.0.0.1:5000/test', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    body: null
  })
  return {text:await response.text(), status:response.status}}


export function test(){
    
    fetchHandling(fetchfun,null)
  };