function TestComp (props){
    const buttonStyle = {
        color: "white",
        backgroundColor: "DodgerBlue",
        padding: "10px",
        fontFamily: "Arial"
    };
    return(
    <div>
        <button style={buttonStyle}>{props.text}</button>
    </div>)
}

export default TestComp;